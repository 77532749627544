import Axios from 'axios'

const createProvisioningUnit = (accessToken, data, server) => Axios.put(
  `${server}/api/mezzo_tool/provisioning_admin`,
  data,
  {
    headers: {
      'Access-Token': accessToken,
    },
  },
)

export default {
  createProvisioningUnit,
}
