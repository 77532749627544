import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AdvancedTools from '../components/MezzoToolTabs/Programming/Tools/AdvancedTools/AdvancedTools'
import {
  sync, set, selectOption, selectOptionValue, selectOptions,
} from '../actions/programOptions'
import { selectCrcValue, selectBinaryCommand, setBinaryCommand } from '../actions/binaryCommands'
import { selectCommand, setCommand } from '../actions/commands'


const mapStateToProps = (state) => {
  const {
    selectedOption, selectedOptions, selectedOptionValue,
  } = state.programOptions
  const { selectedMezzo } = state.mezzoDevices
  const { command, status } = state.commands
  const { status: restoreUnitUsersStatus } = state.restoreUnitUsers
  const {
    binaryCommand, crcValue,
  } = state.binaryCommands
  return {
    selectedOption,
    selectedOptions,
    selectedOptionValue,
    status,
    selectedMezzo,
    restoreUnitUsersStatus,
    command,
    binaryCommand,
    crcValue,
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  selectOption,
  selectOptions,
  selectOptionValue,
  sync,
  set,
  setBinaryCommand,
  selectBinaryCommand,
  selectCrcValue,
  selectCommand,
  setCommand,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedTools)
