import ActionCable from 'actioncable'
import { defineConnectionInfo } from '../config/settings'
import { cloudOpen, localOpen } from '../actions/connection'

const openCable = channel => new Promise((resolve, reject) => {
  const { store } = window
  const { connection: { type } } = store.getState()
  const { servers: { server, endpoints } } = store.getState()

  if (type === 'cloud') {
    const { connection: { cloud: { opened } } } = store.getState()
    if (opened) {
      resolve(global.cloudCable)
      global.cloudCable.connect()
      store.dispatch(cloudOpen())
      return
    }
  } else {
    const { connection: { local: { opened } } } = store.getState()
    if (opened) {
      resolve(global.localCable)
      global.localCable.connect()
      store.dispatch(localOpen())
      return
    }
  }
  const current = defineConnectionInfo(type, server, endpoints)
  const ws = current.ssl ? 'wss' : 'ws'

  let currentAccessToken = 'no-token'
  if (type === 'cloud' || channel === 'MezzoTool::FactoryDevicesChannel') {
    const { secrets: { accessToken } } = store.getState()
    currentAccessToken = accessToken
  }
  const encodeData = data => Object.keys(data).map(key => (
    [key, data[key]].map(encodeURIComponent).join('=')
  )).join('&')
  const params = encodeData({ access_token: currentAccessToken })
  const cable = ActionCable.createConsumer(`${ws}://${current.host}/cable?${params}`)
  if (type === 'cloud') {
    store.dispatch(cloudOpen())
    global.cloudCable = cable
  } else {
    store.dispatch(localOpen())
    global.localCable = cable
  }
  resolve(cable).catch((error) => {
    reject(error)
  })
})

export default openCable
