export const connectionEndpoints = {
  development: {
    ssl: false,
    host: 'localhost:3000',
    applicationId: '3LbGvf924hu3B2bETrhKPB49bDNGdcDQCWvUW-8yRVU',
    secret: 'uzcuu9oAhOJ4w30sgCAbN9Yy2TnOuZAhebKRZfeloXU',
  },
  mezzo: {
    ssl: true,
    host: 'mezzo-api.nesscs.com',
    applicationId: '1bW8tZfwYWOlKbDvm-5c5P4O5VKwGoo92B83wwAGGuk',
    secret: 'iNHZw4huB81JHnSO_AE1Y-RymtW1eZIgDUmCLkwnvJM',
    subdomain: 'mezzo',
  },
  localComms: {
    host: 'localhost:3002',
    ssl: false,
  },
  pony: {
    ssl: true,
    host: 'pony-api.nesscs.com',
    applicationId: 'nYuhn22_8PJ871u_O8z1Hw4cPGxPdNSoZ8oPu30rMsM',
    secret: 'AxvU430yCE6mV9UC-CT8VyjwUO6q-Uk0OGQxnriffYA',
    subdomain: 'pony-api',
  },
  ocelot: {
    ssl: true,
    host: 'ocelot-api.nesscs.com',
    applicationId: 'nYuhn22_8PJ871u_O8z1Hw4cPGxPdNSoZ8oPu30rMsM',
    secret: 'AxvU430yCE6mV9UC-CT8VyjwUO6q-Uk0OGQxnriffYA',
    subdomain: 'ocelot-api',
  },
  smartlink: {
    ssl: true,
    host: 'smartlink-api.nesscs.com',
    applicationId: '9G8WlzKhRKHu60GCg6kyO3hJvJG0b26s1NDxM26YjrQ',
    secret: 'hiORBhvcGt_YLdqBhkh19kF474FtxD4aEbPu7Lz1ee0',
    subdomain: 'lendlease',
  },
}

export const authCredentials = {
  username: 'root',
  password: 'yeeb6ooS',
}

export const defineConnectionInfo = (connectivity, server, endpoints) => {
  if (connectivity === 'cloud') {
    if (endpoints.length) {
      const endpointData = endpoints.find(endpoint => (endpoint.name === server))
      return {
        ssl: true,
        host: endpointData.api_url.slice(8),
        applicationId: endpointData.mezzo_tool_client_id,
        secret: endpointData.mezzo_tool_secret,
      }
    }
    switch (server) {
    case 'mezzo':
      return connectionEndpoints.mezzo
    case 'pony':
      return connectionEndpoints.pony
    case 'ocelot':
      return connectionEndpoints.ocelot
    case 'smartlink':
      return connectionEndpoints.smartlink
    default:
      return connectionEndpoints.pony
    }
  } else {
    return connectionEndpoints.localComms
  }
}
