import { createLogic } from 'redux-logic'
import {
  SET_BINARY_COMMAND,
  SET_CANCEL_BINARY_COMMAND,
} from '../constants/binaryCommands'
import { COMMAND_TIMEOUT } from '../constants/timeouts'

const submitBinaryCommandLogic = createLogic({
  type: SET_BINARY_COMMAND,
  cancelType: SET_CANCEL_BINARY_COMMAND,
  warnTimeout: COMMAND_TIMEOUT,

  transform({ getState, action }, next) {
    const { binaryCommands: { binaryCommand, crcValue } } = getState()
    next({
      ...action,
      binaryCommand,
      crcValue,
    })
  },

  process({ action }, dispatch, done) {
    global.binaryCommandsChannel.perform('execute', { command: action.binaryCommand, auto_crc: action.crcValue })
    done()
  },
})

export default submitBinaryCommandLogic
