import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Logout from '../components/Logout/Logout'
import {
  selectServer,
} from '../actions/servers'
import {
  signOut,
} from '../actions/signIn'
import {
  saveToken,
} from '../actions/secrets'

const mapStateToProps = (state) => {
  const { server } = state.servers
  return {
    server,
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  selectServer,
  saveToken,
  signOut,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Logout)
