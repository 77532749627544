const styles = () => ({
  deviceList: {
    width: 400,
    marginTop: 10,
    color: '#000',
  },
  wrapper: {
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    position: 'relative',
  },
  cloudBlocker: {
    position: 'absolute',
    width: 'calc(100% + 5px)',
    height: '100%',
    left: -3,
    bottom: -5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
    background: '#fff',
    opacity: 0.9,
  },
  signInButton: {
    background: '#3f51b5',
    color: '#fff',
    transition: 'all 0.2s ease',
    '&:hover': {
      opacity: '0.9',
      background: '#3f51b5',
      transform: 'scale(1.1)',
    },
    '&:not(:hover)': {
      opacity: '1',
      background: '#3f51b5',
      transform: 'scale(1)',
    },
  },
  connectWrapper: {
    display: 'flex',
    margin: '10px 0',
    width: '100%',
  },
  button: {
    margin: 0,
  },
  textField: {
    width: 330,
    margin: 0,
  },
  searchField: {
    margin: 0,
    width: '100%',
    marginBottom: 10,
  },
  list: {
    width: '100%',
  },
  listItem: {
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    '&:hover': {
      transform: 'scale(1.03)',
      background: 'rgba(63, 81, 181, 0.1)',
    },
    padding: 5,
  },
  dropList: {
    height: 270,
    overflowY: 'scroll',
  },
  dropListItem: {
    width: 380,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  serial: {
    fontSize: '16px',
    marginRight: 15,
  },
  ssid: {
    fontSize: '14px',
    lineHeight: '22px',
  },
  titlesWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  timestamp: {
    position: 'absolute',
    fontSize: '12px',
    right: 0,
    bottom: -21,
  },
  '@media (max-width: 860px)': {
    wrapper: {
      width: '100%',
    },
    dropListItem: {
      width: '100%',
    },
    deviceList: {
      width: '100%',
    },
  },
})

export default styles
