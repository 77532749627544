import * as types from '../constants/console'

export const updateConsoleInfo = consoleInfo => ({
  type: types.UPDATE_CONSOLE_INFO,
  consoleInfo,
})

export const resetConsoleInfo = () => ({
  type: types.RESET_CONSOLE_INFO,
})
