import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Provisioning from '../../components/MezzoToolTabs/Provisioning/Provisioning'
import {
  signOut,
} from '../../actions/signIn'
import withAutologout from '../../hocs/withAutologout'

let enhance = Provisioning

const mapStateToProps = (state) => {
  const { accessToken, tokenExpireTime } = state.secrets
  return {
    accessToken,
    tokenExpireTime,
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  signOut,
}, dispatch)

enhance = withAutologout(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const ProvisioningEnhanced = enhance

export default ProvisioningEnhanced
