import React from 'react'
import {
  HashRouter,
  Route,
} from 'react-router-dom'

import MezzoToolTabs from './MezzoToolTabs'

const AppRouter = () => (
  <HashRouter>
    <Route exact path="/" component={MezzoToolTabs} />
  </HashRouter>
)

export default AppRouter
