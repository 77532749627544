import React from 'react'
import {
  Button,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Wrapper from '../../../Common/Wrapper/Wrapper'
import styles from './Tools.style'
import TextField from '../../../Common/TextField/TextField'

class Tools extends React.Component {
  cleanSelectedOptions=() => {
    const {
      selectOptions,
      selectOptionValue,
      selectOption,
    } = this.props
    selectOption('')
    selectOptions([])
    selectOptionValue('')
  }

  handleClick=(type) => {
    const {
      selectOptions,
      sync,
    } = this.props
    if (type === 'mezzoInfo') {
      this.cleanSelectedOptions()
      selectOptions([1329, 1331, 1341, 1324, 1327, 1340, 1332, 1330, 1328, 1333])
      sync()
    }
    if (type === 'mezzoErrors') {
      this.cleanSelectedOptions()
      selectOptions([1242])
      sync()
    }
  }

  render() {
    const {
      classes,
      selectOption,
      selectOptions,
      selectedOption,
      sync,
      set,
      selectOptionValue,
      selectedOptionValue,
      selectedMezzo,
    } = this.props
    return (
      <Wrapper>
        <Wrapper className={classes.title}>Tools:</Wrapper>
        <Wrapper className={classes.tools}>
          <Wrapper className={classes.buttonWrapper}>
            <TextField
              label="Programming Option"
              className={classes.textField}
              margin="normal"
              selectOption={selectOption}
              selectedOption={selectedOption}
              disabled={false}
            />
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={() => {
                selectOptions([])
                sync()
              }}
              disabled={!selectedOption || selectedMezzo.serial === 'N/A'}
            >
              Get
            </Button>
          </Wrapper>
          <Wrapper className={classes.buttonWrapper}>
            <TextField
              label="Programming Option value"
              className={classes.textField}
              margin="normal"
              selectOption={selectOptionValue}
              selectedOption={selectedOptionValue}
              disabled={false}
            />
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={() => {
                selectOptions([])
                set()
              }}
              disabled={!selectedOptionValue || !selectedOption || selectedMezzo.serial === 'N/A'}
            >
              Set
            </Button>
          </Wrapper>
        </Wrapper>
        <Wrapper className={classes.buttonsWrapper}>
          <Button
            className={classes.mezzoButtons}
            variant="contained"
            color="primary"
            onClick={() => {
              this.handleClick('mezzoInfo')
            }}
            disabled={selectedMezzo.serial === 'N/A'}
          >
            Get Mezzo Info
          </Button>
          <Button
            className={classes.mezzoButtons}
            variant="contained"
            color="primary"
            onClick={() => {
              this.handleClick('mezzoErrors')
            }}
            disabled={selectedMezzo.serial === 'N/A'}
          >
            Get Mezzo Errors
          </Button>
        </Wrapper>
      </Wrapper>
    )
  }
}

Tools.propTypes = {
  classes: PropTypes.object.isRequired,
  selectOption: PropTypes.func.isRequired,
  selectOptions: PropTypes.func.isRequired,
  selectedOption: PropTypes.string.isRequired,
  selectOptionValue: PropTypes.func.isRequired,
  selectedOptionValue: PropTypes.string.isRequired,
  selectedMezzo: PropTypes.shape({
    serial: PropTypes.string,
    identifier: PropTypes.string,
  }),
  sync: PropTypes.func.isRequired,
  set: PropTypes.func.isRequired,
}

Tools.defaultProps = {
  selectedMezzo: PropTypes.shape({
    serial: PropTypes.string,
    identifier: PropTypes.string,
  }),
}


export default withStyles(styles)(Tools)
