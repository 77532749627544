const convertErrorToConsole = (error) => {
  let fullError = error.msg
  if (error.log) {
    const logs = error.log
    const allLog = logs.map(log => `-> '${log.request}', <- '${log.response}'`).join('\r\n')
    fullError = `${fullError}\r\n${allLog}`
  }
  return fullError
}

export default convertErrorToConsole
