const styles = () => ({
  componentWrapper: {
    maxWidth: 900,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectedMezzo: {
    margin: '0 auto',
  },
  '@media (max-width: 860px)': {
    componentWrapper: {
      flexDirection: 'column',
    },
  },
})

export default styles
