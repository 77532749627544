import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import Wrapper from '../../Common/Wrapper/Wrapper'
import styles from './Provisioning.style'
import MezzoSerialGet from '../../../containers/Provisioning/MezzoSerialGet'
import ProgramMezzo from '../../../containers/Provisioning/ProgramMezzo'
import ProvisioningForm from '../../../containers/Provisioning/ProvisioningForm'
import MezzoMonitoring from './MezzoMonitoring/MezzoMonitoring'
import Tools from '../../../containers/Tools'
import AdvancedTools from '../../../containers/AdvancedTools'
import Logger from '../../../containers/Logger'
import MezzoConnection from '../../../containers/MezzoConnection'

class Provisioning extends React.Component {
  render() {
    const {
      classes,
    } = this.props
    return (
      <Paper className={classes.root}>
        <MezzoConnection />
        <MezzoSerialGet />
        <ProvisioningForm />
        <ProgramMezzo />
        <Wrapper className={classes.monitoringWrapper}>
          <MezzoMonitoring />
        </Wrapper>
        <Wrapper className={classes.toolsWrapper}>
          <Tools />
          <AdvancedTools />
        </Wrapper>
        <Logger />
      </Paper>
    )
  }
}

Provisioning.propTypes = {
  classes: PropTypes.object.isRequired,
}

Provisioning.displayName = 'Provisioning'

export default withStyles(styles)(Provisioning)
