import getSubdomain from './getSubdomain'

const convertProvisioningToBackend = (values, serial, latitude, longitude, server) => {
  const convertedData = {
    serial_number: serial,
    latitude,
    longitude,
    connection_method_po: values.connection_method,
    subdomain: getSubdomain(server),
  }
  return convertedData
}


export default convertProvisioningToBackend
