import {
  SIGN_IN,
  SIGN_OUT,
  FAILED_SIGN_IN,
  DONE_SIGN_IN,
  SAVE_LOGIN,
  SAVE_PASSWORD,
  CLEAR_SIGN_IN_ERROR,
  RESET_SIGN_IN,
  OPEN_SIGN_IN_POPUP,
  CLOSE_SIGN_IN_POPUP,
} from '../constants/signIn'

export const signIn = () => ({
  type: SIGN_IN,
})

export const signInDone = () => ({
  type: DONE_SIGN_IN,
})
export const signInFailed = message => ({
  type: FAILED_SIGN_IN,
  message,
})

export const saveLogin = login => ({
  type: SAVE_LOGIN,
  login,
})

export const savePassword = password => ({
  type: SAVE_PASSWORD,
  password,
})

export const clearSignInError = () => ({
  type: CLEAR_SIGN_IN_ERROR,
})

export const signOut = () => ({
  type: SIGN_OUT,
})

export const resetSignIn = () => ({
  type: RESET_SIGN_IN,
})

export const openSignInPopup = () => ({
  type: OPEN_SIGN_IN_POPUP,
})

export const closeSignInPopup = () => ({
  type: CLOSE_SIGN_IN_POPUP,
})
