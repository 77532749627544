import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Programming from '../components/MezzoToolTabs/Programming/Programming'
import withAutologout from '../hocs/withAutologout'
import {
  signOut,
} from '../actions/signIn'

let enhance = Programming

const mapStateToProps = (state) => {
  const { accessToken, tokenExpireTime } = state.secrets
  return {
    accessToken,
    tokenExpireTime,
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  signOut,
}, dispatch)

enhance = withAutologout(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const ProgrammingEnhanced = enhance

export default ProgrammingEnhanced
