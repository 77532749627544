import * as type from '../constants/binaryCommands'

export const syncSent = () => ({
  type: type.SYNC_SENT_BINARY_COMMANDS,
})

export const syncDone = () => ({
  type: type.SYNC_DONE_BINARY_COMMANDS,
})
export const syncFailed = message => ({
  type: type.SYNC_FAILED_BINARY_COMMANDS,
  message,
})

export const selectBinaryCommand = binaryCommand => ({
  type: type.SELECT_BINARY_COMMAND,
  binaryCommand,
})
export const selectCrcValue = crcValue => ({
  type: type.SELECT_CRC_VALUE,
  crcValue,
})

export const setBinaryCommand = () => ({
  type: type.SET_BINARY_COMMAND,
})

export const updateBinaryCommandsStatus = binaryCommandStatus => ({
  type: type.UPDATE_BINARY_COMMANDS_STATUS,
  binaryCommandStatus,
})

export const unsubscribeBinaryCommands = () => ({
  type: type.UNSUBSCRIBE,
})

export const subscribeBinaryCommands = () => ({
  type: type.SUBSCRIBE,
})
