import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { reduxForm, submit } from 'redux-form'
import MezzoMonitoringForm from '../../../components/MezzoToolTabs/Provisioning/MezzoMonitoring/MezzoMonitoringForm'
import { setPendant } from '../../../actions/programOptions'
import { pendantValues } from '../../../constants/usersInputValues'
import { mezzoMonitoringTitles, fieldNames } from '../../../constants/titles'

const mapStateToProps = (state) => {
  const { pendant } = state.provisioning
  return {
    initialValues: {
      pendant,
    },
    inputValues: pendantValues,
    title: mezzoMonitoringTitles.pendant,
    name: fieldNames.pendant,
  }
}

const mapDispatchToProps = parentDispatch => bindActionCreators({
  submit: dispatch => dispatch(submit('MezzoMonitoringFormPendant')),
}, parentDispatch)

let enhance = reduxForm({
  form: 'MezzoMonitoringFormPendant',
  onSubmit: () => {
    window.store.dispatch(setPendant())
  },
})(MezzoMonitoringForm)


enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)


const MezzoMonitoringEnhanced = enhance

export default MezzoMonitoringEnhanced
