import React from 'react'
import {
  Typography,
} from '@material-ui/core/index'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles/index'
import Wrapper from '../../../../Common/Wrapper/Wrapper'
import styles from './ConnectionType.style'
import SelectString from '../../../../Common/SelectString/SelectString'
import { connectionTypes } from '../../../../../constants/usersInputValues'

class ConnectionType extends React.Component {
  render() {
    const {
      classes,
    } = this.props
    return (
      <Wrapper className={classes.connection}>
        <Typography className={classes.inputLabel}>Connection type</Typography>
        <SelectString
          name="connection_method"
          values={connectionTypes}
        />
      </Wrapper>
    )
  }
}

ConnectionType.propTypes = {
  classes: PropTypes.object.isRequired,
}

ConnectionType.displayName = 'Provisioning'

export default withStyles(styles)(ConnectionType)
