import logger from 'redux-logger'
import invariant from 'redux-immutable-state-invariant'


export const devMiddlewares = () => {
  const middleware = []
  middleware.push(invariant())
  middleware.push(logger)
  return middleware
}

export const devEnhancers = () => {
  const enhancers = []
  return enhancers
}
