import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './App.css'
import ReactDependentScript from 'react-dependent-script'
import PropTypes from 'prop-types'
import { MuiThemeProvider } from '@material-ui/core/styles'
import theme from '../../theme/theme'
import AppRouter from '../../containers/AppRouter'
import checkTokenExpiration from '../../helpers/checkTokenExpiration'
import { signOut } from '../../actions/signIn'

class App extends PureComponent {
  componentDidMount() {
    const { accessToken, tokenExpireTime } = this.props
    if (accessToken.length && checkTokenExpiration(tokenExpireTime)) {
      signOut()
    }
  }

  render() {
    return (
      <ReactDependentScript
        scripts={[
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyAX8HCt8teGLIgx7AWiJY3PDHifmHb7V_s&libraries=places',
        ]}
      >
        <MuiThemeProvider theme={theme}>
          <AppRouter>
            <div className="App" />
          </AppRouter>
        </MuiThemeProvider>
      </ReactDependentScript>
    )
  }
}
const mapStateToProps = (state) => {
  const { accessToken, tokenExpireTime } = state.secrets
  return {
    accessToken,
    tokenExpireTime,
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  signOut,
}, dispatch)

App.propTypes = {
  accessToken: PropTypes.string.isRequired,
  tokenExpireTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
}

const enhance = connect(mapStateToProps, mapDispatchToProps)(App)

export default enhance
