const styles = () => ({
  bottomToolsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonWrapper: {
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 12,
  },
  mezzoRescueButtons: {
    height: 40,
    width: '80%',
  },
  buttonTitle: {
    marginBottom: 10,
  },
  circleColor: {
    color: '#fff',
  },
  '@media (max-width: 860px)': {
    bottomToolsWrapper: {
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    buttonWrapper: {
      width: '50%',
    },
  },
  '@media (max-width: 420px)': {
    buttonWrapper: {
      width: '100%',
    },
    mezzoRescueButtons: {
      width: '100%',
    },
  },
})

export default styles
