export const roleValues = [
  ['Sub Admin', 'sub_admin'],
  ['Family', 'family'],
  ['Guest', 'guest'],
]

export const connectionTypes = [
  ['TCP Auto', 4],
  ['TCP Wifi Only', 5],
  ['TCP Cellular Only', 6],
  ['UDP Auto', 0],
  ['UDP Wifi Only', 1],
  ['UDP Cellular Only', 2],
]

export const pendantValues = [
  ['u1-Pendant1', 'u1-Pendant1'],
  ['u2-Pendant2', 'u2-Pendant2'],
  ['u3-Pendant3', 'u3-Pendant3'],
  ['u4-Pendant4', 'u4-Pendant4'],
  ['u5-Pendant5', 'u5-Pendant5'],
  ['u6-Pendant6', 'u6-Pendant6'],
  ['u7-Pendant7', 'u7-Pendant7'],
  ['u8-Pendant8', 'u8-Pendant8'],
]

export const detectorValues = [
  ['d1-Detector1', 'd1-Detector1'],
  ['d2-Detector2', 'd2-Detector2'],
  ['d3-Detector3', 'd3-Detector3'],
  ['d4-Detector4', 'd4-Detector4'],
  ['d5-Detector5', 'd5-Detector5'],
  ['d6-Detector6', 'd6-Detector6'],
  ['d7-Detector7', 'd7-Detector7'],
  ['d8-Detector8', 'd8-Detector8'],
]
