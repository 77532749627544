import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  Button,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Wrapper from '../../../Common/Wrapper/Wrapper'
import styles from './LocalComms.style'
import TextField from '../../../Common/TextField/TextField'

class LocalComms extends React.Component {
  handleChangeExpansion = () => (event, expanded) => {
    const {
      sync,
      syncDone,
      expandLocalPanel,
      collapseLocalPanel,
      selectMezzo,
      update,
      unsubscribe,
      clearTimestamp,
    } = this.props
    if (expanded === true) {
      expandLocalPanel()
      update([])
      selectMezzo({
        serial: 'N/A',
        identifier: 'N/A',
        ssid: 'N/A',
        ip: 'N/A',
      })
      sync()
    } else {
      collapseLocalPanel()
      update([])
      syncDone()
      unsubscribe()
      clearTimestamp()
    }
  }

  checkDisabled=() => {
    const {
      connectivity,
    } = this.props
    return connectivity !== 'localComms'
  }

  connectToDevice=(serial, identifier, ssid, ip) => () => {
    const {
      selectMezzo,
      collapseLocalPanel,
      syncDone,
      unsubscribe,
      unsubscribeProgramOptions,
      subscribeProgramOptions,
      subscribeCommands,
      unsubscribeCommands,
      subscribeBinaryCommands,
      unsubscribeBinaryCommands,
    } = this.props
    unsubscribeProgramOptions()
    unsubscribeCommands()
    unsubscribeBinaryCommands()
    selectMezzo({
      serial,
      identifier,
      ssid,
      ip,
    })
    subscribeProgramOptions()
    subscribeCommands()
    subscribeBinaryCommands()
    collapseLocalPanel()
    syncDone()
    unsubscribe()
  }

  handleClickOnConnect=(localConnectInfo) => {
    const {
      selectMezzo,
      unsubscribeProgramOptions,
      subscribeProgramOptions,
      subscribeCommands,
      unsubscribeCommands,
      subscribeBinaryCommands,
      unsubscribeBinaryCommands,
    } = this.props
    unsubscribeProgramOptions()
    unsubscribeCommands()
    unsubscribeBinaryCommands()
    selectMezzo({
      identifier: localConnectInfo,
    })
    subscribeProgramOptions()
    subscribeCommands()
    subscribeBinaryCommands()
  }

  render() {
    const {
      classes,
      localDevices,
      isLocalPanelExpanded,
      setLocalConnectInfo,
      localConnectInfo,
      timestamp,
    } = this.props
    return (
      <Wrapper className={classes.wrapper}>
        <Wrapper className={classes.connectWrapper}>
          <TextField
            label="ip:port/user_number"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            selectOption={setLocalConnectInfo}
            selectedOption={localConnectInfo}
            disabled={this.checkDisabled()}
          />
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={() => {
              this.handleClickOnConnect(localConnectInfo)
            }}
            disabled={this.checkDisabled() || !localConnectInfo}
          >
            Connect
          </Button>
        </Wrapper>
        <Accordion
          expanded={isLocalPanelExpanded}
          onChange={this.handleChangeExpansion()}
          className={classes.deviceList}
          disabled={this.checkDisabled()}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1"
          >
            <Wrapper className={classes.titlesWrapper}>
              <Typography className={classes.heading}>HLK Lookup</Typography>
              {isLocalPanelExpanded
              && <Typography className={classes.timestamp}>{`Last update: ${timestamp}`}</Typography>}
            </Wrapper>
          </AccordionSummary>
          <AccordionDetails className={classes.dropList}>
            <List className={classes.list}>
              {
                localDevices.map(device => (
                  <ListItem
                    className={classes.listItem}
                    key={device.identifier}
                    onClick={this.connectToDevice(device.serial_number, device.identifier, device.ssid)}
                  >
                    <Wrapper className={classes.dropListItem}>
                      <Typography>
                        {`${device.ssid} - ${device.ip}`}
                      </Typography>
                    </Wrapper>
                  </ListItem>
                ))
              }
            </List>
          </AccordionDetails>
        </Accordion>
      </Wrapper>
    )
  }
}

LocalComms.propTypes = {
  classes: PropTypes.object.isRequired,
  connectivity: PropTypes.string.isRequired,
  localDevices: PropTypes.arrayOf(PropTypes.shape({
    serial_number: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    identifier: PropTypes.string,
  })),
  sync: PropTypes.func.isRequired,
  syncDone: PropTypes.func.isRequired,
  expandLocalPanel: PropTypes.func.isRequired,
  selectMezzo: PropTypes.func.isRequired,
  collapseLocalPanel: PropTypes.func.isRequired,
  isLocalPanelExpanded: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired,
  unsubscribeProgramOptions: PropTypes.func.isRequired,
  subscribeProgramOptions: PropTypes.func.isRequired,
  unsubscribeCommands: PropTypes.func.isRequired,
  subscribeCommands: PropTypes.func.isRequired,
  setLocalConnectInfo: PropTypes.func.isRequired,
  localConnectInfo: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  clearTimestamp: PropTypes.func.isRequired,
  subscribeBinaryCommands: PropTypes.func.isRequired,
  unsubscribeBinaryCommands: PropTypes.func.isRequired,
}

LocalComms.defaultProps = {
  localDevices: [],
}


export default withStyles(styles)(LocalComms)
