import * as type from '../constants/provisioning'

const initialState = {
  connection_method: 4,
  progress: false,
  errorMessage: '',
  provisioningInfo: {},
  pendant: '',
  detector: '',
  longitude: '150.9061988',
  latitude: '-33.8219067',
}

export default function provisioning(state = initialState, action) {
  switch (action.type) {
  case type.SET_SENT_PROVISIONING:
    return { ...state, errorMessage: '', progress: true }
  case type.SET_DONE_PROVISIONING:
    return { ...state, errorMessage: '', progress: false }
  case type.SET_FAILED_PROVISIONING:
    return { ...state, errorMessage: action.message, progress: false }
  case type.UPDATE_PROVISIONING_INFO:
    return { ...state, provisioningInfo: action.info }

  case type.SAVE_LONGITUDE:
    return { ...state, longitude: action.longitude }
  case type.SAVE_LATTITUDE:
    return { ...state, latitude: action.latitude }

  case type.RESET_PROVISIONING:
    return initialState
  default:
    return state
  }
}
