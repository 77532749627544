import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { selectOption, selectOptions, sync } from '../../actions/programOptions'
import { saveMezzoSerial } from '../../actions/mezzoDevices'

import MezzoSerialGet from '../../components/MezzoToolTabs/Provisioning/MezzoSerialGet/MezzoSerialGet'

const mapStateToProps = (state) => {
  const { serial } = state.mezzoDevices.selectedMezzo
  return {
    serialNumber: serial,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  saveMezzoSerial,
  selectOption,
  selectOptions,
  sync,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MezzoSerialGet)
