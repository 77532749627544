import React from 'react'
import {
  Paper,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Wrapper from '../../Common/Wrapper/Wrapper'
import styles from './Programming.style'
import Tools from '../../../containers/Tools'
import AdvancedTools from '../../../containers/AdvancedTools'
import Logger from '../../../containers/Logger'
import MezzoConnection from '../../../containers/MezzoConnection'

class Programming extends React.Component {
  render() {
    const {
      classes,
    } = this.props
    return (
      <Paper className={classes.root}>
        <MezzoConnection />
        <Wrapper className={classes.toolsWrapper}>
          <Tools />
          <AdvancedTools />
        </Wrapper>
        <Logger />
      </Paper>
    )
  }
}

Programming.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Programming)
