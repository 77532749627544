import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  Select,
} from '@material-ui/core/index'

class SelectFieldString extends React.PureComponent {
  render() {
    const {
      input,
      number,
      values,
      startValue,
      createItems,
      disabled,
    } = this.props
    return (
      <FormControl>
        <Select
          inputProps={{ ...input }}
          onChange={(event, index, value) => input.onChange(value)}
          disabled={disabled}
        >
          {createItems(number, values, startValue)}
        </Select>
      </FormControl>
    )
  }
}

SelectFieldString.propTypes = {
  input: PropTypes.object.isRequired,
  createItems: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
  number: PropTypes.number,
  startValue: PropTypes.number,
  disabled: PropTypes.bool,
}

SelectFieldString.defaultProps = {
  values: [],
  startValue: 0,
  number: null,
  disabled: false,
}

export default SelectFieldString
