const convertOptionsToProgram = (provisioningInfo, connectionType) => {
  if (Object.entries(provisioningInfo).length === 0) {
    return false
  }
  const users = provisioningInfo.program_options
  const { ip } = provisioningInfo.endpoint
  const { token } = provisioningInfo.unit_device
  const convertedData = [...users,
    { number: 1337, value: token },
    { number: 1347, value: connectionType },
    { number: 1321, value: ip },
  ]
  return convertedData
}


export default convertOptionsToProgram
