import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import LocalComms from '../components/MezzoToolTabs/MezzoConnection/LocalComms/LocalComms'
import {
  collapseLocalPanel, expandLocalPanel, selectMezzo, sync, syncDone,
  update, unsubscribe, setLocalConnectInfo, clearTimestamp,
} from '../actions/mezzoDevices'
import {
  unsubscribeProgramOptions,
  subscribeProgramOptions,
} from '../actions/programOptions'
import {
  subscribeCommands,
  unsubscribeCommands,
} from '../actions/commands'
import {
  subscribeBinaryCommands,
  unsubscribeBinaryCommands,
} from '../actions/binaryCommands'

const mapStateToProps = (state) => {
  const { type } = state.connection
  const {
    mezzoDevices: localDevices, selectedMezzo, isLocalPanelExpanded, selectedCheckbox, localConnectInfo, timestamp,
  } = state.mezzoDevices
  return {
    connectivity: type,
    localDevices,
    selectedMezzo,
    isLocalPanelExpanded,
    selectedCheckbox,
    localConnectInfo,
    timestamp,
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  sync,
  syncDone,
  expandLocalPanel,
  collapseLocalPanel,
  selectMezzo,
  update,
  setLocalConnectInfo,
  subscribeProgramOptions,
  unsubscribeProgramOptions,
  subscribeCommands,
  unsubscribeCommands,
  refresh: sync,
  tryAgain: sync,
  unsubscribe,
  clearTimestamp,
  subscribeBinaryCommands,
  unsubscribeBinaryCommands,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LocalComms)
