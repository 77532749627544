import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Tools from '../components/MezzoToolTabs/Programming/Tools/Tools'
import {
  sync, set, selectOption, selectOptionValue, selectOptions,
} from '../actions/programOptions'

const mapStateToProps = (state) => {
  const {
    selectedOption, selectedOptionValue,
  } = state.programOptions
  const { selectedMezzo } = state.mezzoDevices
  const { status } = state.commands
  const { status: restoreUnitUsersStatus } = state.restoreUnitUsers
  return {
    selectedOption,
    selectedOptionValue,
    status,
    selectedMezzo,
    restoreUnitUsersStatus,
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  selectOption,
  selectOptions,
  selectOptionValue,
  sync,
  set,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Tools)
