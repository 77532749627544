export const SET_PROVISIONING = '@@provisioning/set'
export const SET_SENT_PROVISIONING = '@@provisioning/setSent'
export const SET_CANCEL_PROVISIONING = '@@provisioning/setCancelled'
export const SET_FAILED_PROVISIONING = '@@provisioning/setFailed'
export const SET_DONE_PROVISIONING = '@@provisioning/setDone'
export const UPDATE_PROVISIONING_INFO = '@@provisioning/updateProvisioningInfo'

export const SAVE_LONGITUDE = '@@provisioning/saveLongitude'
export const SAVE_LATTITUDE = '@@provisioning/saveLatitude'

export const RESET_PROVISIONING = '@@provisioning/reset'
