export const UPDATE_MEZZO_DEVICES = '@@mezzoDevices/update'

export const SYNC_MEZZO_DEVICES = '@@mezzoDevices/sync'
export const SYNC_SENT_MEZZO_DEVICES = '@@mezzoDevices/syncSent'
export const SYNC_CANCEL_MEZZO_DEVICES = '@@mezzoDevices/syncCancelled'
export const SYNC_FAILED_MEZZO_DEVICES = '@@mezzoDevices/syncFailed'
export const SYNC_DONE_MEZZO_DEVICES = '@@mezzoDevices/syncDone'

export const EXPAND_CLOUD_PANEL = '@@mezzoDevices/expandCloudPanel'
export const COLLAPSE_CLOUD_PANEL = '@@mezzoDevices/collapseCloudPanel'
export const EXPAND_LOCAL_PANEL = '@@mezzoDevices/expandLocalPanel'
export const COLLAPSE_LOCAL_PANEL = '@@mezzoDevices/collapseLocalPanel'

export const SELECT_MEZZO = '@@mezzoDevices/selectMezzo'

export const UNSUBSCRIBE = '@@mezzoDevices/unsubscribe'

export const SAVE_MEZZO_SERIAL = '@@mezzoDevices/saveMezzoSerial'
export const SET_LOCAL_CONNECT_INFO = '@@mezzoDevices/setLocalConnectInfo'
export const SET_CLOUD_CONNECT_INFO = '@@mezzoDevices/setCloudConnectInfo'

export const UPDATE_TIMESTAMP = '@@mezzoDevices/updateTimestamp'
export const CLEAR_TIMESTAMP = '@@mezzoDevices/clearTimestamp'

export const SUBSCRIBE_FACTORY_DEVICES = '@@mezzoDevices/subscribeFactoryDevices'
export const SYNC_FACTORY_DEVICES_SENT = '@@mezzoDevices/syncFactoryDevicesSent'
export const SYNC_FACTORY_DEVICES_DONE = '@@mezzoDevices/syncFactoryDevicesDone'
export const UNSUBSCRIBE_FACTORY_DEVICES = '@@mezzoDevices/unsubscribeFactoryDevices'
export const CANCEL_SUBSCRIBE_FACTORY_DEVICES = '@@mezzoDevices/cancel_subscribeFactoryDevices'

export const RESET_MEZZO_DEVICES = '@@mezzoDevices/reset'
