import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { reduxForm, submit } from 'redux-form'
import MezzoMonitoringForm from '../../../components/MezzoToolTabs/Provisioning/MezzoMonitoring/MezzoMonitoringForm'
import { setDetector } from '../../../actions/programOptions'
import { detectorValues } from '../../../constants/usersInputValues'
import { mezzoMonitoringTitles, fieldNames } from '../../../constants/titles'

const mapStateToProps = (state) => {
  const { detector } = state.provisioning
  return {
    initialValues: {
      detector,
    },
    inputValues: detectorValues,
    title: mezzoMonitoringTitles.detector,
    name: fieldNames.detector,
  }
}

const mapDispatchToProps = parentDispatch => bindActionCreators({
  submit: dispatch => dispatch(submit('MezzoMonitoringFormDetector')),
}, parentDispatch)

let enhance = reduxForm({
  form: 'MezzoMonitoringFormDetector',
  onSubmit: () => {
    window.store.dispatch(setDetector())
  },
})(MezzoMonitoringForm)


enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)


const MezzoMonitoringEnhanced = enhance

export default MezzoMonitoringEnhanced
