const styles = () => ({
  title: {
    color: '#000',
    marginTop: 20,
    marginBottom: 10,
  },
  buttonProgram: {
    width: 210,
  },
  '@media (max-width: 640px)': {
    buttonProgram: {
      width: '100%',
    },
  },
})

export default styles
