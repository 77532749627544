const styles = () => ({
  infoList: {
    padding: 0,
    marginTop: 20,
    display: 'flex',
  },
  alert: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    '& .MuiAlert-icon': {
      height: 20,
    },
  },
  message: {
    width: '100%',
    padding: 0,
  },
  accordion: {
    background: 'none',
    boxShadow: 'none',
    border: 'none',
  },
  dropInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    cursor: 'pointer',
  },
})

export default styles
