import * as type from '../constants/secrets'

const initialState = {
  accessToken: '',
  tokenExpireTime: '',
}

export default function secrets(state = initialState, action) {
  switch (action.type) {
  case type.SAVE_TOKEN:
    return { accessToken: action.token, tokenExpireTime: action.tokenExpireTime }
  case type.RESET_SECRETS:
    return initialState
  default:
    return state
  }
}
