import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import {
  ListItem,
  Typography,
  MenuItem,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from './SelectString.style'
import SelectFieldString from './SelectFieldString'

class SelectString extends React.Component {
    createItems = (number, values) => values.map(el => (
      <MenuItem key={number + el[1]} value={el[1]}>
        { el[0] }
      </MenuItem>
    ))


    render() {
      const {
        classes,
        number,
        title,
        name,
        value,
        values,
        startValue,
        ...other
      } = this.props

      return (
        <ListItem className={classes.root}>
          <Typography
            className={classes.title}
          >
            {title}
          </Typography>
          <Field
            name={name}
            component={SelectFieldString}
            number={number}
            createItems={this.createItems}
            value={value}
            values={values}
            startValue={startValue}
            {...other}
          />
        </ListItem>
      )
    }
}

SelectString.propTypes = {
  classes: PropTypes.object.isRequired,
  number: PropTypes.number,
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  startValue: PropTypes.number,
  values: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
}

SelectString.defaultProps = {
  values: [],
  startValue: 0,
  value: null,
  number: null,
  title: '',
}

export default (withStyles(styles)(SelectString))
