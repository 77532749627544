import * as type from '../constants/mezzoDevices'

export const update = mezzoDevices => ({
  type: type.UPDATE_MEZZO_DEVICES,
  mezzoDevices,
})

export const sync = () => ({
  type: type.SYNC_MEZZO_DEVICES,
})

export const syncSent = () => ({
  type: type.SYNC_SENT_MEZZO_DEVICES,
})

export const syncDone = () => ({
  type: type.SYNC_DONE_MEZZO_DEVICES,
})

export const expandCloudPanel = () => ({
  type: type.EXPAND_CLOUD_PANEL,
})

export const collapseCloudPanel = () => ({
  type: type.COLLAPSE_CLOUD_PANEL,
})

export const expandLocalPanel = () => ({
  type: type.EXPAND_LOCAL_PANEL,
})

export const collapseLocalPanel = () => ({
  type: type.COLLAPSE_LOCAL_PANEL,
})

export const selectMezzo = mezzo => ({
  type: type.SELECT_MEZZO,
  mezzo,
})

export const unsubscribe = () => ({
  type: type.UNSUBSCRIBE,
})

export const saveMezzoSerial = serial => ({
  type: type.SAVE_MEZZO_SERIAL,
  serial,
})

export const setLocalConnectInfo = info => ({
  type: type.SET_LOCAL_CONNECT_INFO,
  info,
})

export const setCloudConnectInfo = info => ({
  type: type.SET_CLOUD_CONNECT_INFO,
  info,
})

export const resetMezzoDevices = () => ({
  type: type.RESET_MEZZO_DEVICES,
})

export const updateTimestamp = timestamp => ({
  type: type.UPDATE_TIMESTAMP,
  timestamp,
})

export const clearTimestamp = () => ({
  type: type.CLEAR_TIMESTAMP,
})

export const subscribeFactoryDevices = () => ({
  type: type.SUBSCRIBE_FACTORY_DEVICES,
})

export const syncFactoryDevicesSent = () => ({
  type: type.SYNC_FACTORY_DEVICES_SENT,
})

export const syncFactoryDevicesDone = () => ({
  type: type.SYNC_FACTORY_DEVICES_DONE,
})

export const unsubscribeFactoryDevices = () => ({
  type: type.UNSUBSCRIBE_FACTORY_DEVICES,
})
