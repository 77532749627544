export const SELECT_COMMAND = '@@commands/selectCommand'

export const UPDATE_COMMANDS_STATUS = '@@commands/updateStatus'

export const SYNC_SENT_COMMANDS = '@@commands/syncSent'
export const SYNC_FAILED_COMMANDS = '@@commands/syncFailed'
export const SYNC_DONE_COMMANDS = '@@commands/syncDone'

export const SET_COMMAND = '@@commands/setCommand'
export const SET_CANCEL_COMMAND = '@@commands/setCancelCommand'

export const UNSUBSCRIBE = '@@commands/unsubscribe'
export const SUBSCRIBE = '@@commands/subscribe'
export const CANCEL_SUBSCRIBE = '@@commands/cancel_subscribe'

export const SET_COMMAND_CURRENT_INDEX = '@@commands/setCommandCurrentIndex'
export const RESET_COMMAND_CURRENT_INDEX = '@@commands/resetCommandCurrentIndex'
