const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 900,
    padding: 25,
    boxShadow: 'none',
  },
  title: {
    marginTop: 0,
    color: '#000',
    marginBottom: 10,
  },
  radioGroup: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  radioButton: {
    display: 'flex',
  },
})

export default styles
