import Axios from 'axios'

const loginToMezzoTool = (currentEndpoint, username, password) => Axios.post(
  `https://${currentEndpoint.host}/oauth/token`, '',
  {
    params: {
      grant_type: 'password',
      username: `${username}`,
      password: `${password}`,
      client_id: currentEndpoint.applicationId,
      client_secret: currentEndpoint.secret,
      scope: 'mezzoTool',
    },
  },
)

export default {
  loginToMezzoTool,
}
