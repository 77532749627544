import { createLogic } from 'redux-logic'
import * as type from '../constants/mezzoDevices'
import {
  update, syncSent,
} from '../actions/mezzoDevices'
import mezzoDevicesChannel from '../api/mezzoDevicesChannel'

const subscribeMezzoDevicesLogic = createLogic({
  type: type.SYNC_MEZZO_DEVICES,
  cancelType: type.SYNC_CANCEL_MEZZO_DEVICES,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    mezzoDevicesChannel({
      request: () => {
        dispatch(syncSent())
      },
      response: (data) => {
        window.store.dispatch(update(data))
      },
    })
  },
})

export default subscribeMezzoDevicesLogic
