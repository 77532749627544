import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  Button,
  TextField as SearchField,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Wrapper from '../../../Common/Wrapper/Wrapper'
import styles from './Cloud.style'
import TextField from '../../../Common/TextField/TextField'
import checkTokenExpiration from '../../../../helpers/checkTokenExpiration'

class Cloud extends React.Component {
  state = {
    initialItems: [],
    items: [],
  }

  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  componentDidUpdate() {
    const { initialItems } = this.state
    if (initialItems.length !== 0) this.inputRef.current.focus()
  }

  static getDerivedStateFromProps(props, state) {
    if (props.cloudDevices !== state.initialItems) {
      return {
        initialItems: props.cloudDevices,
        items: props.cloudDevices,
      }
    }
    return null
  }

  handleChangeExpansion = () => (event, expanded) => {
    const {
      sync,
      syncDone,
      expandCloudPanel,
      collapseCloudPanel,
      selectMezzo,
      update,
      unsubscribe,
      clearTimestamp,
    } = this.props
    if (expanded === true) {
      expandCloudPanel()
      update([])
      selectMezzo({
        serial: 'N/A',
        identifier: 'N/A',
      })
      sync()
    } else {
      collapseCloudPanel()
      update([])
      syncDone()
      unsubscribe()
      clearTimestamp()
    }
  }

  checkDisabled=() => {
    const {
      connectivity,
    } = this.props
    return connectivity !== 'cloud'
  }

  connectToDevice=(serial, identifier, ssid) => () => {
    const {
      selectMezzo,
      collapseCloudPanel,
      syncDone,
      unsubscribe,
      unsubscribeProgramOptions,
      subscribeProgramOptions,
      subscribeCommands,
      unsubscribeCommands,
      subscribeBinaryCommands,
      unsubscribeBinaryCommands,
    } = this.props
    unsubscribeProgramOptions()
    unsubscribeCommands()
    unsubscribeBinaryCommands()
    selectMezzo({
      serial,
      identifier,
      ssid,
    })
    subscribeProgramOptions()
    subscribeCommands()
    subscribeBinaryCommands()
    collapseCloudPanel()
    syncDone()
    unsubscribe()
  }

  handleClickOnConnect=(cloudConnectInfo) => {
    const {
      selectMezzo,
      unsubscribeProgramOptions,
      subscribeProgramOptions,
      subscribeCommands,
      unsubscribeCommands,
      subscribeBinaryCommands,
      unsubscribeBinaryCommands,
    } = this.props
    unsubscribeProgramOptions()
    unsubscribeCommands()
    unsubscribeBinaryCommands()
    selectMezzo({
      identifier: cloudConnectInfo,
    })
    subscribeProgramOptions()
    subscribeCommands()
    subscribeBinaryCommands()
  }

  filterList = (e) => {
    const { initialItems } = this.state
    let updateList = initialItems

    updateList = updateList.filter(item => (item.serial_number.toString().search(e.target.value) !== -1
      || item.ssid.toString().toLowerCase().search(e.target.value.toLowerCase()) !== -1))

    this.setState({ items: updateList })
  }

  render() {
    const {
      classes,
      isCloudPanelExpanded,
      setCloudConnectInfo,
      cloudConnectInfo,
      timestamp,
      openSignInPopup,
      tokenExpireTime,
    } = this.props
    const { items, initialItems } = this.state
    return (
      <Wrapper className={classNames(classes.wrapper, classes.blur)}>
        {checkTokenExpiration(tokenExpireTime) && (
          <Wrapper className={classes.cloudBlocker}>
            <Button
              className={classes.signInButton}
              variant="outlined"
              color="primary"
              onClick={openSignInPopup}
            >
              SIGN IN TO USE CLOUD DEVICES
            </Button>
          </Wrapper>
        )}
        <Wrapper className={classes.connectWrapper}>
          <TextField
            label="Mezzo Unit Token"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            selectOption={setCloudConnectInfo}
            selectedOption={cloudConnectInfo}
            disabled={this.checkDisabled()}
          />
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={() => {
              this.handleClickOnConnect(cloudConnectInfo)
            }}
            disabled={this.checkDisabled() || !cloudConnectInfo}
          >
            Connect
          </Button>
        </Wrapper>
        <Accordion
          expanded={isCloudPanelExpanded}
          onChange={this.handleChangeExpansion()}
          className={classes.deviceList}
          disabled={this.checkDisabled()}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2"
          >
            <Wrapper className={classes.titlesWrapper}>
              <Typography className={classes.heading}>Mezzo&apos;s online</Typography>
              {isCloudPanelExpanded
              && <Typography className={classes.timestamp}>{`Last update: ${timestamp}`}</Typography>}
            </Wrapper>
          </AccordionSummary>
          <AccordionDetails className={classes.dropList}>
            <List className={classes.list}>
              <SearchField
                label="search"
                className={classes.searchField}
                margin="normal"
                variant="outlined"
                onChange={this.filterList}
                disabled={initialItems.length === 0}
                inputRef={this.inputRef}
              />
              {
                items.map(device => (
                  <ListItem
                    className={classes.listItem}
                    key={device.identifier}
                    onClick={this.connectToDevice(device.serial_number, device.identifier, device.ssid)}
                  >
                    <Wrapper className={classes.dropListItem}>
                      <Wrapper className={classes.infoWrapper}>
                        <Typography className={classes.serial}>
                          {device.serial_number}
                        </Typography>
                        <Typography className={classes.ssid}>
                          {device.ssid}
                        </Typography>
                      </Wrapper>
                    </Wrapper>
                  </ListItem>
                ))
              }
            </List>
          </AccordionDetails>
        </Accordion>
      </Wrapper>
    )
  }
}

Cloud.propTypes = {
  classes: PropTypes.object.isRequired,
  connectivity: PropTypes.string.isRequired,
  sync: PropTypes.func.isRequired,
  syncDone: PropTypes.func.isRequired,
  expandCloudPanel: PropTypes.func.isRequired,
  selectMezzo: PropTypes.func.isRequired,
  collapseCloudPanel: PropTypes.func.isRequired,
  isCloudPanelExpanded: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired,
  unsubscribeProgramOptions: PropTypes.func.isRequired,
  subscribeProgramOptions: PropTypes.func.isRequired,
  unsubscribeCommands: PropTypes.func.isRequired,
  subscribeCommands: PropTypes.func.isRequired,
  setCloudConnectInfo: PropTypes.func.isRequired,
  cloudConnectInfo: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  clearTimestamp: PropTypes.func.isRequired,
  subscribeBinaryCommands: PropTypes.func.isRequired,
  unsubscribeBinaryCommands: PropTypes.func.isRequired,
  openSignInPopup: PropTypes.func.isRequired,
  tokenExpireTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
}

export default withStyles(styles)(Cloud)
