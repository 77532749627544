import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { connectRouter } from 'connected-react-router'
import app from './app'
import mezzoDevices from './mezzoDevices'
import programOptions from './programOptions'
import commands from './commands'
import binaryCommands from './binaryCommands'
import provisioning from './provisioning'
import connection from './connection'
import servers from './servers'
import signIn from './signIn'
import secrets from './secrets'
import restoreUnitUsers from './restoreUnitUsers'
import console from './console'

export const rootReducer = history => combineReducers({
  app,
  connection,
  mezzoDevices,
  programOptions,
  commands,
  binaryCommands,
  provisioning,
  servers,
  signIn,
  secrets,
  restoreUnitUsers,
  console,

  router: connectRouter(history),
  form: formReducer,
})
