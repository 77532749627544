import update from 'immutability-helper'
import * as type from '../constants/commands'

const initialState = {
  errorMessage: '',
  command: '',
  status: '',
  commandCurrentIndex: 0,
}

export default function commands(state = initialState, action) {
  switch (action.type) {
  case type.SELECT_COMMAND:
    return { ...state, command: action.command }
  case type.UPDATE_COMMANDS_STATUS:
    return update(state, {
      status: { $set: action.status },
    })
  case type.SYNC_FAILED_COMMANDS:
    return { ...state, errorMessage: action.message }

  case type.SET_COMMAND_CURRENT_INDEX:
    return { ...state, commandCurrentIndex: state.commandCurrentIndex + 1 }

  case type.RESET_COMMAND_CURRENT_INDEX:
    return { ...state, commandCurrentIndex: initialState.commandCurrentIndex }

  default:
    return state
  }
}
