import * as type from '../constants/commands'

export const syncSent = () => ({
  type: type.SYNC_SENT_COMMANDS,
})

export const syncDone = () => ({
  type: type.SYNC_DONE_COMMANDS,
})
export const syncFailed = message => ({
  type: type.SYNC_FAILED_COMMANDS,
  message,
})

export const selectCommand = command => ({
  type: type.SELECT_COMMAND,
  command,
})

export const setCommand = () => ({
  type: type.SET_COMMAND,
})

export const updateCommandsStatus = status => ({
  type: type.UPDATE_COMMANDS_STATUS,
  status,
})

export const unsubscribeCommands = () => ({
  type: type.UNSUBSCRIBE,
})

export const subscribeCommands = () => ({
  type: type.SUBSCRIBE,
})

export const setCommandCurrentIndex = () => ({
  type: type.SET_COMMAND_CURRENT_INDEX,
})

export const resetCommandCurrentIndex = () => ({
  type: type.RESET_COMMAND_CURRENT_INDEX,
})
