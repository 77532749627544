import { createLogic } from 'redux-logic'
import {
  SET_COMMAND,
  SET_CANCEL_COMMAND,
} from '../constants/commands'
import { COMMAND_TIMEOUT } from '../constants/timeouts'

const submitCommandLogic = createLogic({
  type: SET_COMMAND,
  cancelType: SET_CANCEL_COMMAND,
  warnTimeout: COMMAND_TIMEOUT,

  transform({ getState, action }, next) {
    const { commands: { command, commandCurrentIndex } } = getState()
    const commandsSequence = command.split('\n').filter(el => el !== '')
    next({
      ...action,
      command,
      commandsSequence,
      commandCurrentIndex,
    })
  },

  process({ action }, dispatch, done) {
    global.commandsChannel.perform('execute', {
      command: action.commandsSequence.length > 1
        ? action.commandsSequence[action.commandCurrentIndex]
        : action.command.trim(),
      auto_crc: action.crcValue,
    })
    done()
  },
})

export default submitCommandLogic
