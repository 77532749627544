import * as type from '../constants/restoreUnitUsers'

const initialState = {
  status: '',
  inProgress: false,
}

export default function resque(state = initialState, action) {
  switch (action.type) {
  case type.RESTORE_UNIT_USERS_SENT:
    return { ...state, status: '', inProgress: true }
  case type.RESTORE_UNIT_USERS_DONE:
    return { ...state, status: action.status, inProgress: false }
  case type.RESTORE_UNIT_USERS_FAILED:
    return { ...state, status: action.status, inProgress: false }
  default:
    return state
  }
}
