import { createLogic } from 'redux-logic'
import {
  SIGN_IN,
  CANCEL_SIGN_IN,
} from '../constants/signIn'
import {
  signInFailed, signInDone, closeSignInPopup,
} from '../actions/signIn'
import { saveToken } from '../actions/secrets'
import { unsubscribeFactoryDevices } from '../actions/mezzoDevices'
import { switchType as chooseConnectivity } from '../actions/connection'
import Api from '../api/signIn'
import { connectionEndpoints } from '../config/settings'
import parseJwt from '../helpers/parseJwt'
import { saveEndpoints } from '../actions/servers'


const signInLogic = createLogic({
  type: SIGN_IN,
  cancelType: CANCEL_SIGN_IN,

  transform({ getState, action }, next) {
    const { signIn: { login, password } } = getState()
    const { servers: { server, endpoints } } = getState()
    const currentEndpoint = connectionEndpoints[server]
    next({
      ...action,
      currentEndpoint,
      login,
      password,
      endpoints,
    })
  },

  process({ action }, dispatch, done) {
    Api.loginToMezzoTool(action.currentEndpoint, action.login, action.password)
      .then((response) => {
        const dataJwt = parseJwt(response.data.access_token)
        const tokenExpireTime = dataJwt.iat + 86400
        dispatch(saveToken(response.data.access_token, tokenExpireTime))
        dispatch(signInDone())
        const safeEndpointsData = action.endpoints.map((endpoint) => {
          const {
            client_id, secret, mezzo_tool_client_id, mezzo_tool_secret, ...rest
          } = endpoint
          return rest
        })
        dispatch(saveEndpoints(safeEndpointsData))
        dispatch(unsubscribeFactoryDevices())
        dispatch(chooseConnectivity('cloud'))
        dispatch(closeSignInPopup())
      })
      .catch((error) => {
        if (error) {
          dispatch(signInFailed(error.response.data.error_reason))
        }
      })
      .then(() => done())
  },
})

export default signInLogic
