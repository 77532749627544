const getEndpointApiUrl = (server) => {
  switch (server) {
  case 'mezzo':
    return 'https://mezzo-api.nesscs.com'
  case 'smartlink':
    return 'https://smartlink-api.nesscs.com'
  case 'pony':
    return 'https://pony-api.nesscs.com'
  case 'ocelot':
    return 'https://ocelot-api.nesscs.com'
  default:
    return null
  }
}


export default getEndpointApiUrl
