import React from 'react'
import {
  Typography,
  Button,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Wrapper from '../../../Common/Wrapper/Wrapper'
import styles from './BottomTools.style'

class BottomTools extends React.Component {
  render() {
    const {
      classes,
      selectedMezzo,
      restoreUnitUsers,
      restoreUnitUsersInProgress,
      connectivity,
    } = this.props
    return (
      <Wrapper className={classes.bottomToolsWrapper}>
        <Wrapper className={classes.buttonWrapper}>
          <Typography className={classes.buttonTitle}>Restore Unit Users</Typography>
          <Button
            disabled={(selectedMezzo.serial === 'N/A' || selectedMezzo.serial === '') || connectivity === 'localComms'}
            className={classes.mezzoRescueButtons}
            variant="contained"
            color="primary"
            onClick={() => {
              restoreUnitUsers()
            }}
          >
            {restoreUnitUsersInProgress ? (
              <CircularProgress
                color="primary"
                classes={{ colorPrimary: classes.circleColor }}
                size={30}
              />
            ) : 'Restore'}
          </Button>
        </Wrapper>
        <Wrapper className={classes.buttonWrapper}>
          <Typography className={classes.buttonTitle}>Backup Mezzo Data</Typography>
          <Button
            disabled
            className={classes.mezzoRescueButtons}
            variant="contained"
            color="primary"
            onClick={() => {}}
          >
            Backup
          </Button>
        </Wrapper>
        <Wrapper className={classes.buttonWrapper}>
          <Typography className={classes.buttonTitle}>Restore Mezzo Data</Typography>
          <Button
            disabled
            className={classes.mezzoRescueButtons}
            variant="contained"
            color="primary"
            onClick={() => {}}
          >
            Restore
          </Button>
        </Wrapper>
        <Wrapper className={classes.buttonWrapper}>
          <Typography className={classes.buttonTitle}>Update Mezzo Firmware</Typography>
          <Button
            disabled
            className={classes.mezzoRescueButtons}
            variant="contained"
            color="primary"
            onClick={() => {}}
          >
            Update
          </Button>
        </Wrapper>
      </Wrapper>
    )
  }
}

BottomTools.propTypes = {
  classes: PropTypes.object.isRequired,
  connectivity: PropTypes.string.isRequired,
  restoreUnitUsers: PropTypes.func.isRequired,
  restoreUnitUsersInProgress: PropTypes.bool.isRequired,
  selectedMezzo: PropTypes.shape({
    serial: PropTypes.string,
    identifier: PropTypes.string,
  }),
}

BottomTools.defaultProps = {
  selectedMezzo: PropTypes.shape({
    serial: PropTypes.string,
    identifier: PropTypes.string,
  }),
}


export default withStyles(styles)(BottomTools)
