import React from 'react'
import {
  Accordion, AccordionDetails,
  AccordionSummary,
  Button, Checkbox, TextField as MaterialTextField, Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Wrapper from '../../../../Common/Wrapper/Wrapper'
import styles from './AdvancedTools.style'
import TextField from '../../../../Common/TextField/TextField'

class AdvancedTools extends React.Component {
  state = {
    isExpanded: false,
  }

  handleChange = panel => (event, isExpanded) => {
    this.setState({ isExpanded: isExpanded ? panel : false })
  }

  cleanSelectedOptions=() => {
    const {
      selectOptions,
      selectOptionValue,
      selectOption,
    } = this.props
    selectOption('')
    selectOptions([])
    selectOptionValue('')
  }

  handleClick=(type) => {
    const {
      sync,
      selectOptions,
      selectOption,
      selectOptionValue,
      set,
    } = this.props
    if (type === 'mezzoFirmwareRev') {
      this.cleanSelectedOptions()
      selectOptions([1340])
      sync()
    }
    if (type === 'cellularRSSI') {
      this.cleanSelectedOptions()
      selectOptions([1231])
      sync()
    }
    if (type === 'rebootMezzo') {
      this.cleanSelectedOptions()
      selectOption('2003')
      selectOptionValue('1')
      set()
      this.cleanSelectedOptions()
    }
  }

  handleChangeCrcCheckbox=(event) => {
    const {
      selectCrcValue,
    } = this.props
    selectCrcValue(event.target.checked)
  }

  handleCommandChange = (event) => {
    const {
      selectCommand,
    } = this.props
    if (event.target.value === '') {
      selectCommand('')
    } else {
      selectCommand(event.target.value)
    }
  }

  renderMezzoBinaryCommandFields=() => {
    const {
      classes,
      selectBinaryCommand,
      setBinaryCommand,
      binaryCommand,
      selectedMezzo,
      crcValue,
    } = this.props
    return (
      <>
        <Wrapper className={classes.binaryCommandWrapper}>
          <Wrapper className={classes.binaryCommandButtonWrapper}>
            <TextField
              label="Mezzo binary command"
              className={classes.textField}
              margin="normal"
              selectOption={selectBinaryCommand}
              selectedOption={binaryCommand}
              disabled={false}
            />
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={() => {
                setBinaryCommand()
              }}
              disabled={selectedMezzo.identifier === 'N/A' || !binaryCommand}
            >
              Send
            </Button>
          </Wrapper>
          <Wrapper className={classes.checkboxWrapper}>
            <Typography>
              with crc
            </Typography>
            <Checkbox
              className={classes.checkbox}
              onChange={this.handleChangeCrcCheckbox}
              color="primary"
              inputProps={{
                'aria-label': 'primary checkbox',
              }}
              checked={crcValue}
            />
          </Wrapper>
        </Wrapper>
      </>
    )
  }

  render() {
    const {
      classes,
      command,
      setCommand,
      selectedMezzo,
    } = this.props
    const { isExpanded } = this.state
    return (
      <Wrapper className={classes.root}>
        <Accordion
          className={classes.accordion}
          expanded={isExpanded === 'advanced'}
          onChange={this.handleChange('advanced')}
        >
          <AccordionSummary
            aria-controls="info-content"
            id="info-header"
          >
            <Wrapper className={classes.title}>
              {isExpanded ? 'Hide advanced tools' : 'Show advanced tools'}
            </Wrapper>
          </AccordionSummary>
          <AccordionDetails className={classes.dropTools}>
            <Wrapper className={classes.tools}>
              <Wrapper className={classes.buttonWrapper}>
                <MaterialTextField
                  label="Mezzo command or commands sequence"
                  name="Mezzo command or commands sequence"
                  onChange={this.handleCommandChange}
                  className={classes.areaField}
                  multiline
                  margin="normal"
                  rows="2"
                  variant="outlined"
                  value={command}
                  disabled={false}
                />
                <Button
                  className={classes.commandsButton}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setCommand()
                  }}
                  disabled={selectedMezzo.serial === 'N/A' || !command}
                >
                  Send
                </Button>
              </Wrapper>
              {this.renderMezzoBinaryCommandFields()}
            </Wrapper>
            <Wrapper className={classes.buttonsWrapper}>
              <Button
                className={classes.mezzoButtons}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.handleClick('mezzoFirmwareRev')
                }}
                disabled={selectedMezzo.serial === 'N/A'}
              >
                Get Mezzo Firmware Rev
              </Button>
              <Button
                className={classes.mezzoButtons}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.handleClick('cellularRSSI')
                }}
                disabled={selectedMezzo.serial === 'N/A'}
              >
                Get Cellular RSSI
              </Button>
              <Button
                className={classes.mezzoButtons}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.handleClick('rebootMezzo')
                }}
                disabled={selectedMezzo.serial === 'N/A'}
              >
                Reboot Mezzo
              </Button>
            </Wrapper>
          </AccordionDetails>
        </Accordion>
      </Wrapper>
    )
  }
}

AdvancedTools.propTypes = {
  classes: PropTypes.object.isRequired,
  selectOption: PropTypes.func.isRequired,
  selectOptions: PropTypes.func.isRequired,
  selectOptionValue: PropTypes.func.isRequired,
  selectBinaryCommand: PropTypes.func.isRequired,
  setBinaryCommand: PropTypes.func.isRequired,
  binaryCommand: PropTypes.string.isRequired,
  command: PropTypes.string.isRequired,
  setCommand: PropTypes.func.isRequired,
  selectedMezzo: PropTypes.shape({
    serial: PropTypes.string,
    identifier: PropTypes.string,
  }),
  sync: PropTypes.func.isRequired,
  set: PropTypes.func.isRequired,
  crcValue: PropTypes.bool.isRequired,
  selectCrcValue: PropTypes.func.isRequired,
  selectCommand: PropTypes.func.isRequired,
}

AdvancedTools.defaultProps = {
  selectedMezzo: PropTypes.shape({
    serial: PropTypes.string,
    identifier: PropTypes.string,
  }),
}


export default withStyles(styles)(AdvancedTools)
