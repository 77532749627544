export const SELECT_BINARY_COMMAND = '@@binaryCommands/selectCommand'
export const SELECT_CRC_VALUE = '@@binaryCommands/selectCrcValue'

export const UPDATE_BINARY_COMMANDS_STATUS = '@@binaryCommands/updateStatus'

export const SYNC_SENT_BINARY_COMMANDS = '@@binaryCommands/syncSent'
export const SYNC_FAILED_BINARY_COMMANDS = '@@binaryCommands/syncFailed'
export const SYNC_DONE_BINARY_COMMANDS = '@@binaryCommands/syncDone'

export const SET_BINARY_COMMAND = '@@binaryCommands/setCommand'
export const SET_CANCEL_BINARY_COMMAND = '@@binaryCommands/setCancelCommand'

export const UNSUBSCRIBE = '@@binaryCommands/unsubscribe'
export const SUBSCRIBE = '@@binaryCommands/subscribe'
export const CANCEL_SUBSCRIBE = '@@binaryCommands/cancel_subscribe'
