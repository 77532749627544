import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Typography,
} from '@material-ui/core/'
import { withStyles } from '@material-ui/core/styles'
import styles from './Logout.style'
import Wrapper from '../Common/Wrapper/Wrapper'

class Logout extends React.Component {
   handleLogoutClick=() => {
     const { signOut } = this.props
     signOut()
   }

   render() {
     const
       {
         classes,
         server,
       } = this.props
     return (
       <Wrapper className={classes.wrapper}>
         <Typography className={classes.serverName}>{server}</Typography>
         <Button
           className={classes.button}
           variant="outlined"
           color="primary"
           onClick={() => {
             this.handleLogoutClick()
           }}
         >
           Logout
         </Button>
       </Wrapper>
     )
   }
}

Logout.propTypes = {
  classes: PropTypes.object.isRequired,
  server: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  signOut: PropTypes.func.isRequired,
}

Logout.defaultProps = {
  history: null,
}


export default withStyles(styles)(Logout)
