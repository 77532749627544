const styles = () => ({
  tabsWrapper: {
    maxWidth: 900,
    width: '100%',
    boxShadow: 'none',
    border: 'none',
    margin: '0 auto',
  },
  messageWrapper: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  desktopOnly: {
    fontSize: '20px',
    fontWeight: 600,
  },
  tooltip: {
    fontSize: '13px',
  },
  '@media (max-width: 640px)': {
    tab: {
      fontSize: '12px',
    },
  },
})

export default styles
