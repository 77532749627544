import * as type from '../constants/signIn'


const initialState = {
  login: '',
  password: '',
  errorMessage: '',
  isSignInPopupOpen: false,
}

export default function signIn(state = initialState, action) {
  switch (action.type) {
  case type.SAVE_LOGIN:
    return { ...state, login: action.login }
  case type.SAVE_PASSWORD:
    return { ...state, password: action.password }
  case type.FAILED_SIGN_IN:
    return { ...state, errorMessage: action.message }
  case type.CLEAR_SIGN_IN_ERROR:
    return { ...state, errorMessage: '' }
  case type.OPEN_SIGN_IN_POPUP:
    return { ...state, isSignInPopupOpen: true }
  case type.CLOSE_SIGN_IN_POPUP:
    return { ...state, isSignInPopupOpen: false }
  case type.RESET_SIGN_IN:
    return initialState

  default:
    return state
  }
}
