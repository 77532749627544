import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  withStyles,
  TextField as MaterialTextField,
} from '@material-ui/core'
import styles from './TextField.style'

class TextField extends React.Component {
  handleChange = (event) => {
    const {
      selectOption,
    } = this.props
    if (event.target.value === '') {
      selectOption('')
    } else {
      selectOption(event.target.value)
    }
  }

  render() {
    const {
      classes, label, selectedOption, disabled,
    } = this.props
    return (
      <FormControl className={classes.formControl}>
        <MaterialTextField
          value={selectedOption}
          label={label}
          name={label}
          className={classes.textField}
          variant="outlined"
          onChange={this.handleChange}
          disabled={disabled}
        />
      </FormControl>
    )
  }
}

TextField.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  selectOption: PropTypes.func.isRequired,
  selectedOption: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

TextField.defaultProps = {
  disabled: true,
}

export default withStyles(styles)(TextField)
