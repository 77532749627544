import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  NativeSelect,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@material-ui/core/'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import BuildIcon from '@material-ui/icons/Build'
import { withStyles } from '@material-ui/core/styles'
import styles from './ServersSelect.style'
import Wrapper from '../Common/Wrapper/Wrapper'
import endpointsRequest from '../../api/endpoints'
import getEndpointApiUrl from '../../helpers/getEndpointApiUrl'

class ServersSelect extends React.Component {
  state ={
    showPassword: false,
    loading: false,
  }

  componentDidMount() {
    const {
      saveEndpoints,
      resetEndpointsData,
      selectServer,
      server,
      setCurrentEndpoint,
    } = this.props
    document.addEventListener('keydown', this.signInByButton)
    resetEndpointsData()
    this.setState({ loading: true });
    (async () => {
      try {
        const response = await endpointsRequest()
        saveEndpoints(response.data)
        if (!server) {
          selectServer(response.data[0].name)
        }
        setCurrentEndpoint(response.data.filter(endpoint => (endpoint.name === server))[0].api_url)
        setTimeout(() => {
          this.setState({ loading: false })
        }, 1000)
      } catch (err) {
        saveEndpoints([])
        setCurrentEndpoint(getEndpointApiUrl(server))
        setTimeout(() => {
          this.setState({ loading: false })
        }, 1000)
      }
    })()
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.signInByButton)
  }

  signInByButton = e => e.code === 'Enter' && this.handleSubmitClick()

  handleServerChange = (event) => {
    const {
      selectServer, setCurrentEndpoint, endpoints,
    } = this.props
    const currentEndpointUrl = endpoints.filter(endpoint => (endpoint.name === event.target.value))[0].api_url
    selectServer(event.target.value)
    setCurrentEndpoint(currentEndpointUrl)
  }


  handleClickShowPassword = () => {
    const {
      showPassword,
    } = this.state
    this.setState({ showPassword: !showPassword })
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault()
  };

  handleLoginChange=(evt) => {
    const {
      saveLogin,
    } = this.props
    saveLogin(evt.target.value)
  }

  handlePasswordChange=(evt) => {
    const {
      savePassword,
    } = this.props
    savePassword(evt.target.value)
  }

  changeRadio=(event) => {
    const {
      selectServer,
      setCurrentEndpoint,
    } = this.props
    selectServer(event.target.value)
    setCurrentEndpoint(getEndpointApiUrl(event.target.value))
  }

  handleSubmitClick=() => {
    const { signIn, clearSignInError } = this.props
    clearSignInError()
    signIn()
  }

  createServerComponent = () => {
    const
      {
        classes,
        server,
        endpoints,
      } = this.props
    const { loading } = this.state
    return (
      <>
        {loading ? (<CircularProgress className={classes.loader} />) : (
          <Wrapper className={classes.wrapper}>
            {endpoints.length ? (
              <FormControl className={classes.formControlSelect}>
                <InputLabel shrink htmlFor="server-placeholder">
                  Select server
                </InputLabel>
                <NativeSelect
                  value={server}
                  onChange={this.handleServerChange}
                  inputProps={{
                    name: 'serverName',
                    id: 'server-placeholder',
                  }}
                >
                  {endpoints.map((endpoint, index) => (
                    <option
                      key={`endpoint-${index + 1}`}
                      value={endpoint.name}
                    >
                      {`${endpoint.name.charAt(0).toUpperCase() + endpoint.name.substr(1)}`}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            ) : (
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="operands"
                  name="operands"
                  className={classes.group}
                  value={server}
                  onChange={(event) => {
                    this.changeRadio(event)
                  }}
                >
                  <FormControlLabel
                    className={classes.label}
                    value="mezzo"
                    control={<Radio color="primary" />}
                    label="Production"
                  />
                  <FormControlLabel
                    className={classes.label}
                    value="pony"
                    control={<Radio color="primary" />}
                    label="Pony"
                  />
                  <FormControlLabel
                    className={classes.label}
                    value="ocelot"
                    control={<Radio color="primary" />}
                    label="Ocelot"
                  />
                  <FormControlLabel
                    className={classes.label}
                    value="smartlink"
                    control={<Radio color="primary" />}
                    label="Smartlink"
                  />
                </RadioGroup>
              </FormControl>
            )}
          </Wrapper>
        )}
      </>
    )
  }

  createTextFields = () => {
    const
      {
        login,
        password,
        classes,
      } = this.props
    const
      {
        showPassword,
      } = this.state
    return (
      <FormControl className={classes.textFormControl}>
        <TextField
          value={login}
          label="Email"
          name="email"
          className={classes.textField}
          variant="outlined"
          autoComplete="username"
          onChange={(evt) => {
            this.handleLoginChange(evt)
          }}
        />
        <TextField
          value={password}
          type={showPassword ? 'text' : 'password'}
          label="Password"
          name="password"
          className={classes.textField}
          variant="outlined"
          onChange={(evt) => {
            this.handlePasswordChange(evt)
          }}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  onMouseDown={this.handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    )
  }

  render() {
    const
      {
        classes,
        errorMessage,
        version,
        closeSignInPopup,
      } = this.props
    return (
      <React.Fragment>
        <form className={classes.main}>
          <Wrapper className={classes.loginForm}>
            <Wrapper
              className={classes.close}
              onClick={closeSignInPopup}
            />
            <Wrapper className={classes.titleWrapper}>
              <Typography className={classes.title}>Mezzo Tool</Typography>
              <BuildIcon className={classes.buildIcon} />
            </Wrapper>
            <Wrapper className={classes.root}>
              {this.createServerComponent()}
              {this.createTextFields()}
              <Typography className={classes.errorMessage}>{errorMessage}</Typography>
            </Wrapper>
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={() => {
                this.handleSubmitClick()
              }}
            >
              Sign in
            </Button>
            <Typography className={classes.appVersion}>{`v${version}`}</Typography>
          </Wrapper>
        </form>
      </React.Fragment>
    )
  }
}

ServersSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  selectServer: PropTypes.func.isRequired,
  saveLogin: PropTypes.func.isRequired,
  savePassword: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  server: PropTypes.string.isRequired,
  login: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  clearSignInError: PropTypes.func.isRequired,
  version: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  saveEndpoints: PropTypes.func.isRequired,
  resetEndpointsData: PropTypes.func.isRequired,
  endpoints: PropTypes.PropTypes.arrayOf(PropTypes.object),
  setCurrentEndpoint: PropTypes.func.isRequired,
  closeSignInPopup: PropTypes.func.isRequired,
}

ServersSelect.defaultProps = {
  history: null,
  endpoints: [],
}


export default withStyles(styles)(ServersSelect)
