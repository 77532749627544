import update from 'immutability-helper'
import * as type from '../constants/programOptions'

const initialState = {
  errorMessage: '',
  selectedOption: '',
  selectedOptionValue: '',
  selectedOptions: [],
  programOptions: {},
  rawCommand: '',
  activity: [],
}

export default function programOptions(state = initialState, action) {
  switch (action.type) {
  case type.SELECT_PROGRAM_OPTIONS:
    return update(state, {
      selectedOptions: { $set: action.options },
    })
  case type.SELECT_PROGRAM_OPTION:
    return { ...state, selectedOption: action.option }
  case type.SELECT_PROGRAM_OPTION_VALUE:
    return { ...state, selectedOptionValue: action.value }

  case type.SYNC_FAILED_PROGRAM_OPTIONS:
    return { ...state, errorMessage: action.message }

  case type.UPDATE_PROGRAM_OPTIONS: {
    const data = action.programOptions.reduce((result, option) => {
      result[option.number] = option.value
      return result
    }, {})
    const updatedState = { ...state, programOptions: { ...state.programOptions, ...data } }
    return update(updatedState, {
      activity: { $push: [...action.programOptions] },
    })
  }

  case type.RESET_PROGRAM_OPTIONS:
    return initialState

  default:
    return state
  }
}
