const styles = () => ({
  deviceList: {
    width: 400,
    marginTop: 10,
    color: '#000',
  },
  wrapper: {
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  connectWrapper: {
    display: 'flex',
    margin: '10px 0',
    width: '100%',
  },
  button: {
    margin: 0,
  },
  textField: {
    width: 330,
    margin: 0,
  },
  list: {
    width: '100%',
  },
  listItem: {
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    '&:hover': {
      transform: 'scale(1.03)',
      background: 'rgba(63, 81, 181, 0.1)',
    },
    padding: 5,
  },
  dropList: {
    height: 270,
    overflowY: 'scroll',
  },
  dropListItem: {
    width: 380,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titlesWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  timestamp: {
    position: 'absolute',
    fontSize: '12px',
    right: 0,
    bottom: -21,
  },
  '@media (max-width: 860px)': {
    wrapper: {
      width: '100%',
    },
    dropListItem: {
      width: '100%',
    },
    deviceList: {
      width: '100%',
    },
  },
})

export default styles
