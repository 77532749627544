const styles = () => ({
  connection: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    maxWidth: 400,
  },
  connectionType: {
    marginTop: -24,
    marginLeft: 10,
  },
  inputLabel: {
    width: '40%',
  },
  '@media (max-width: 640px)': {
    connection: {
      maxWidth: '100%',
      justifyContent: 'space-between',
    },
  },
})

export default styles
