import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ServersSelect from '../components/ServersSelect/ServersSelect'
import {
  selectServer,
  saveEndpoints,
  resetEndpointsData,
  setCurrentEndpoint,
} from '../actions/servers'
import {
  saveLogin,
  savePassword,
  signIn,
  clearSignInError,
  closeSignInPopup,
} from '../actions/signIn'

const mapStateToProps = (state) => {
  const { server, endpoints } = state.servers
  const { login, password, errorMessage } = state.signIn
  const { version } = state.app
  return {
    server,
    endpoints,
    login,
    password,
    errorMessage,
    version,
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  selectServer,
  saveLogin,
  savePassword,
  signIn,
  clearSignInError,
  saveEndpoints,
  resetEndpointsData,
  setCurrentEndpoint,
  closeSignInPopup,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ServersSelect)
