const getSubdomain = (server) => {
  switch (server) {
  case 'mezzo':
    return 'cloud'
  case 'pony':
    return 'pony-api'
  case 'smartlink':
    return 'lendlease'
  default:
    return 'dev-api'
  }
}


export default getSubdomain
