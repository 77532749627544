import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { setProgram } from '../../actions/programOptions'

import ProgramMezzo from '../../components/MezzoToolTabs/Provisioning/ProgramMezzo/ProgramMezzo'


const mapDispatchToProps = dispatch => bindActionCreators({
  setProgram,
}, dispatch)

export default connect(null, mapDispatchToProps)(ProgramMezzo)
