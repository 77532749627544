import * as types from '../constants/connection'

export const cloudOpen = () => ({
  type: types.CLOUD_CONNECTION_OPEN,
})

export const localOpen = () => ({
  type: types.LOCAL_CONNECTION_OPEN,
})

export const cloudClose = () => ({
  type: types.CLOUD_CONNECTION_CLOSE,
})

export const localClose = () => ({
  type: types.LOCAL_CONNECTION_CLOSE,
})

export const switchType = connectionType => ({
  type: types.SWITCH_CONNECTION_TYPE,
  connectionType,
})

export const resetConnection = () => ({
  type: types.RESET_CONNECTION,
})
