import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Wrapper from '../../../Common/Wrapper/Wrapper'
import TextField from '../../../Common/TextField/TextField'
import styles from './MezzoSerialGet.style'

class MezzoSerialGet extends React.Component {
  render() {
    const {
      classes,
      saveMezzoSerial,
      serialNumber,
    } = this.props
    return (
      <Wrapper>
        <Wrapper className={classes.title}>Provision:</Wrapper>
        <Wrapper className={classes.buttonWrapper}>
          <TextField
            label="Mezzo Serial"
            margin="normal"
            selectOption={saveMezzoSerial}
            selectedOption={serialNumber === 'N/A' ? '' : serialNumber}
            disabled={false}
          />
        </Wrapper>
      </Wrapper>
    )
  }
}

MezzoSerialGet.propTypes = {
  classes: PropTypes.object.isRequired,
  saveMezzoSerial: PropTypes.func.isRequired,
  serialNumber: PropTypes.string.isRequired,
}

export default withStyles(styles)(MezzoSerialGet)
