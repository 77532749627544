import Axios from 'axios'

const restoreUnitUsers = (accessToken, server, data) => Axios.post(
  `${server}/api/mezzo_tool/restore_unit_users`, data,
  {
    headers: {
      'Access-Token': accessToken,
    },
  },
)

export default {
  restoreUnitUsers,
}
