const styles = () => ({
  wrapper: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'baseline',
  },
  serverName: {
    textTransform: 'capitalize',
    marginBottom: 10,
    marginRight: 10,
  },

})

export default styles
