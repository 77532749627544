export const SIGN_IN = '@@signIn/signIn'
export const SIGN_OUT = '@@signIn/signOut'
export const CANCEL_SIGN_IN = '@@signIn/signInCancelled'
export const FAILED_SIGN_IN = '@@signIn/signInFailed'
export const DONE_SIGN_IN = '@@signIn/signInDone'

export const SAVE_LOGIN = '@@signIn/saveLogin'
export const SAVE_PASSWORD = '@@signIn/savePassword'

export const CLEAR_SIGN_IN_ERROR = '@@signIn/clearSignInError'
export const RESET_SIGN_IN = '@@signIn/reset'

export const OPEN_SIGN_IN_POPUP = '@@signIn/openPopup'
export const CLOSE_SIGN_IN_POPUP = '@@signIn/closePopup'
