import { CHANNEL_BINARY_COMMANDS } from '../constants/channels'
import openCable from './cable'
import { updateBinaryCommandsStatus } from '../actions/binaryCommands'
import { updateConsoleInfo } from '../actions/console'

const binaryCommandsChannel = ({ request, response }) => {
  openCable(CHANNEL_BINARY_COMMANDS).then((cable) => {
    const { mezzoDevices: { selectedMezzo: { identifier } } } = window.store.getState()
    const params = {
      channel: CHANNEL_BINARY_COMMANDS,
      mezzo_device_identifier: identifier,
    }
    const subscription = cable.subscriptions.create(params, {
      connected() {
        request(this)
      },
      received(data) {
        let result
        if (data.error) {
          result = data.error
        } else if (data.response) {
          result = data.response
        } else {
          result = data.status
        }
        window.store.dispatch(updateBinaryCommandsStatus(result))
        window.store.dispatch(updateConsoleInfo(result))
        response(data)
      },
      disconnected: () => {
        global.binaryCommandsChannel = undefined
      },
    })
    global.binaryCommandsChannel = subscription
    return subscription
  })
}

export default binaryCommandsChannel
