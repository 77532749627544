import { createLogic } from 'redux-logic'
import * as type from '../constants/programOptions'
import {
  update, syncSent, syncFailed, syncDone,
} from '../actions/programOptions'
import programOptionChannel from '../api/programOptionsChannel'
import convertErrorToConsole from '../helpers/convertErrorToConsole'


const subscribeProgramOptionsLogic = createLogic({
  type: type.SUBSCRIBE,
  cancelType: type.CANCEL_SUBSCRIBE,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    programOptionChannel({
      request: () => {
        dispatch(syncSent())
      },
      response: (data, isFinished) => {
        const { programOptions, error } = data
        if (error) {
          const fullError = convertErrorToConsole(error)
          window.store.dispatch(syncFailed(fullError))
        } else {
          window.store.dispatch(update(programOptions))
          if (isFinished) {
            window.store.dispatch(syncDone())
          }
        }
      },
    })
  },
})

export default subscribeProgramOptionsLogic
