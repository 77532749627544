const styles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: 250,
  },
  title: {
    marginRight: 20,
  },
  '@media (max-width: 640px)': {
    root: {
      maxWidth: '100%',
      width: '100%',
      padding: 0,
    },
  },
})

export default styles
