import packageJson from '../../package.json'

const initialState = {
  version: packageJson.version,
}

export default function app(state = initialState, action) {
  switch (action.type) {
  default:
    return state
  }
}
