import { CHANNEL_MEZZO_DEVICES } from '../constants/channels'
import openCable from './cable'
import { updateTimestamp } from '../actions/mezzoDevices'

const mezzoDevicesChannel = ({ request, response }) => {
  openCable(CHANNEL_MEZZO_DEVICES).then((cable) => {
    const subscription = cable.subscriptions.create(CHANNEL_MEZZO_DEVICES, {
      connected() {
        request(this)
      },
      received(data) {
        window.store.dispatch(updateTimestamp(new Date().toLocaleString()))
        response(data)
      },
      disconnected: () => {
        global.mezzoDevicesChannel = undefined
      },
    })
    global.mezzoDevicesChannel = subscription
    return subscription
  })
}

export default mezzoDevicesChannel
