import React from 'react'
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Wrapper from '../../../Common/Wrapper/Wrapper'
import styles from './ConnectivityRadioGroup.style'

class ConnectivityRadioGroup extends React.Component {
  componentDidMount() {
    const {
      connectivity,
      chooseConnectivity,
      subscribeFactoryDevices,
    } = this.props
    if (!connectivity.length) {
      subscribeFactoryDevices()
      chooseConnectivity('localComms')
    }
  }

  reset=() => {
    const {
      collapseCloudPanel,
      collapseLocalPanel,
      clearTimestamp,
    } = this.props
    collapseCloudPanel()
    collapseLocalPanel()
    clearTimestamp()
  }

  handleChange=(event) => {
    const {
      chooseConnectivity,
      subscribeFactoryDevices,
      unsubscribeFactoryDevices,
    } = this.props
    if (event.target.value === 'localComms') {
      subscribeFactoryDevices()
    } else {
      unsubscribeFactoryDevices()
    }
    chooseConnectivity(event.target.value)
    this.reset()
  }

  render() {
    const {
      classes,
      connectivity,
    } = this.props
    return (
      <React.Fragment>
        <Wrapper className={classes.title}>Connectivity:</Wrapper>
        <FormControl component="fieldset">
          <RadioGroup
            className={classes.radioGroup}
            onChange={this.handleChange}
            value={connectivity}
          >
            <FormControlLabel
              className={classes.radioButton}
              value="localComms"
              control={<Radio color="primary" />}
              label="Local Comms"
            />
            <FormControlLabel
              className={classes.radioButton}
              value="cloud"
              control={<Radio color="primary" />}
              label="Cloud"
            />
          </RadioGroup>
        </FormControl>
      </React.Fragment>
    )
  }
}

ConnectivityRadioGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  chooseConnectivity: PropTypes.func.isRequired,
  connectivity: PropTypes.string.isRequired,
  collapseCloudPanel: PropTypes.func.isRequired,
  collapseLocalPanel: PropTypes.func.isRequired,
  clearTimestamp: PropTypes.func.isRequired,
  subscribeFactoryDevices: PropTypes.func.isRequired,
  unsubscribeFactoryDevices: PropTypes.func.isRequired,
}
export default withStyles(styles)(ConnectivityRadioGroup)
