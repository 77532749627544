import {
  SYNC_SENT_RESTORE_UNIT_USERS,
  SYNC_FAILED_RESTORE_UNIT_USERS,
  SYNC_DONE_RESTORE_UNIT_USERS,

  RESTORE_UNIT_USERS,
  RESTORE_UNIT_USERS_SENT,
  RESTORE_UNIT_USERS_FAILED,
  RESTORE_UNIT_USERS_DONE,

  UNSUBSCRIBE,
  SUBSCRIBE,
} from '../constants/restoreUnitUsers'

export const syncSent = () => ({
  type: SYNC_SENT_RESTORE_UNIT_USERS,
})

export const syncDone = () => ({
  type: SYNC_DONE_RESTORE_UNIT_USERS,
})
export const syncFailed = message => ({
  type: SYNC_FAILED_RESTORE_UNIT_USERS,
  message,
})

export const unsubscribeRestoreUnitUsers = () => ({
  type: UNSUBSCRIBE,
})

export const subscribeRestoreUnitUsers = () => ({
  type: SUBSCRIBE,
})

export const restore = () => ({
  type: RESTORE_UNIT_USERS,
})

export const restoreSent = () => ({
  type: RESTORE_UNIT_USERS_SENT,
})

export const restoreDone = status => ({
  type: RESTORE_UNIT_USERS_DONE,
  status,
})
export const restoreFailed = status => ({
  type: RESTORE_UNIT_USERS_FAILED,
  status,
})
