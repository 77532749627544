import { createLogic } from 'redux-logic'
import { CHANNEL_BINARY_COMMANDS } from '../constants/channels'
import { UNSUBSCRIBE } from '../constants/binaryCommands'


const unsubscribeBinaryCommands = createLogic({
  type: UNSUBSCRIBE,

  process(_, dispatch, done) {
    if (global.localCable) {
      global.localCable.subscriptions.subscriptions.forEach(
        sub => sub.identifier.includes(CHANNEL_BINARY_COMMANDS) && sub.unsubscribe(),
      )
    }
    if (global.cloudCable) {
      global.cloudCable.subscriptions.subscriptions.forEach(
        sub => sub.identifier.includes(CHANNEL_BINARY_COMMANDS) && sub.unsubscribe(),
      )
    }
    done()
  },
})

export default unsubscribeBinaryCommands
