import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Rescue from '../components/MezzoToolTabs/Rescue/Rescue'
import {
  subscribeRestoreUnitUsers,
  unsubscribeRestoreUnitUsers,
  restore as restoreUnitUsers,
} from '../actions/restoreUnitUsers'
import {
  signOut,
} from '../actions/signIn'
import withAutologout from '../hocs/withAutologout'

let enhance = Rescue

const mapStateToProps = (state) => {
  const { type } = state.connection
  const { accessToken, tokenExpireTime } = state.secrets
  const { selectedMezzo } = state.mezzoDevices
  const { inProgress: restoreUnitUsersInProgress } = state.restoreUnitUsers
  return {
    connectivity: type,
    accessToken,
    tokenExpireTime,
    selectedMezzo,
    restoreUnitUsersInProgress,
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  signOut,
  subscribeRestoreUnitUsers,
  unsubscribeRestoreUnitUsers,
  restoreUnitUsers,
}, dispatch)

enhance = withAutologout(enhance)
enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const RescueEnhanced = enhance

export default RescueEnhanced
