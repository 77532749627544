import React from 'react'
import PropTypes from 'prop-types'

class Wrapper extends React.PureComponent {
  render() {
    const { children, customStyle, ...other } = this.props
    return (
      <div {...other} style={customStyle}>
        { children }
      </div>
    )
  }
}

Wrapper.propTypes = {
  children: PropTypes.node,
  customStyle: PropTypes.string,
}

Wrapper.defaultProps = {
  customStyle: null,
  children: null,
}

export default Wrapper
