import React from 'react'
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Alert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Wrapper from '../../../Common/Wrapper/Wrapper'
import styles from './MezzoInfo.style'
import mezzoDevicesChecker from '../../../../helpers/mezzoDevicesChecker'

class MezzoInfo extends React.Component {
  state = {
    isExpanded: false,
  }

  handleChange = panel => (event, isExpanded) => {
    this.setState({ isExpanded: isExpanded ? panel : false })
  }

  render() {
    const {
      classes,
      selectedMezzo,
    } = this.props
    const {
      isExpanded,
    } = this.state
    return (
      <Wrapper className={classes.infoList}>
        <Alert
          severity={mezzoDevicesChecker(selectedMezzo) ? 'error' : 'success'}
          className={classes.alert}
          classes={{
            message: classes.message,
          }}
        >
          <Accordion
            className={classes.accordion}
            expanded={isExpanded === 'info'}
            onChange={this.handleChange('info')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="info-content"
              id="info-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                {`Mezzo selected: ${selectedMezzo.ssid}`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.dropInfo} onClick={this.handleChange('info')}>
              <Typography>
                {`ssid: ${selectedMezzo.ssid}`}
              </Typography>
              <Typography>
                {`ip: ${selectedMezzo.ip}`}
              </Typography>
              <Typography>
                {`serial: ${selectedMezzo.serial}`}
              </Typography>
              <Typography>
                {`identifier: ${selectedMezzo.identifier}`}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Alert>
      </Wrapper>
    )
  }
}

MezzoInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedMezzo: PropTypes.shape({
    ssid: PropTypes.string,
    ip: PropTypes.string,
    serial: PropTypes.string,
    identifier: PropTypes.string,
  }),
}

MezzoInfo.defaultProps = {
  selectedMezzo: PropTypes.shape({
    ssid: PropTypes.string,
    ip: PropTypes.string,
    serial: PropTypes.string,
    identifier: PropTypes.string,
  }),
}


export default withStyles(styles)(MezzoInfo)
