import update from 'immutability-helper'
import * as type from '../constants/mezzoDevices'


const initialState = {
  mezzoDevices: [],
  errorMessage: '',
  cloudConnection: false,
  localConnection: false,
  selectedMezzo:
    {
      serial: 'N/A',
      identifier: 'N/A',
      ssid: 'N/A',
      ip: 'N/A',
    },
  localConnectInfo: '',
  cloudConnectInfo: '',
  isCloudPanelExpanded: false,
  isLocalPanelExpanded: false,
  timestamp: '',
}

export default function mezzoDevices(state = initialState, action) {
  switch (action.type) {
  case type.SYNC_SENT_MEZZO_DEVICES:
    return { ...state, cloudConnection: true }
  case type.SYNC_DONE_MEZZO_DEVICES:
    return { ...state, cloudConnection: false }
  case type.SYNC_FAILED_MEZZO_DEVICES:
    return { ...state, errorMessage: action.message }
  case type.UPDATE_MEZZO_DEVICES:
    return { ...state, mezzoDevices: action.mezzoDevices }

  case type.EXPAND_CLOUD_PANEL:
    return { ...state, isCloudPanelExpanded: true }
  case type.COLLAPSE_CLOUD_PANEL:
    return { ...state, isCloudPanelExpanded: false }

  case type.EXPAND_LOCAL_PANEL:
    return { ...state, isLocalPanelExpanded: true }
  case type.COLLAPSE_LOCAL_PANEL:
    return { ...state, isLocalPanelExpanded: false }

  case type.SELECT_MEZZO:
    return { ...state, selectedMezzo: action.mezzo }

  case type.SAVE_MEZZO_SERIAL:
    return { ...state, selectedMezzo: { ...state, serial: action.serial } }

  case type.SET_LOCAL_CONNECT_INFO:
    return { ...state, localConnectInfo: action.info }
  case type.SET_CLOUD_CONNECT_INFO:
    return { ...state, cloudConnectInfo: action.info }

  case type.UPDATE_TIMESTAMP:
    return update(state, {
      timestamp: { $set: action.timestamp },
    })

  case type.CLEAR_TIMESTAMP:
    return update(state, {
      timestamp: { $set: '' },
    })

  case type.RESET_MEZZO_DEVICES:
    return initialState

  default:
    return state
  }
}
