import React from 'react'
import {
  Button,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Wrapper from '../../../Common/Wrapper/Wrapper'
import styles from './ProgramMezzo.style'

class ProgramMezzo extends React.Component {
  render() {
    const {
      classes,
      setProgram,
    } = this.props
    return (
      <Wrapper>
        <Wrapper className={classes.title}>Program Mezzo Users and Data (Local Only)</Wrapper>
        <Button
          className={classes.buttonProgram}
          variant="contained"
          color="primary"
          onClick={() => {
            setProgram()
          }}
        >
          Program
        </Button>
      </Wrapper>
    )
  }
}

ProgramMezzo.propTypes = {
  classes: PropTypes.object.isRequired,
  setProgram: PropTypes.func.isRequired,
}


export default withStyles(styles)(ProgramMezzo)
