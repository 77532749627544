import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MezzoToolTabs from '../components/MezzoToolTabs/MezzoToolTabs'

const mapStateToProps = (state) => {
  const { tokenExpireTime } = state.secrets
  return {
    tokenExpireTime,
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MezzoToolTabs)
