import React from 'react'
import PropTypes from 'prop-types'
import checkTokenExpiration from '../helpers/checkTokenExpiration'

const withAutologout = (WrappedComponent) => {
  class Autologoutable extends React.Component {
    componentDidMount() {
      const { accessToken, tokenExpireTime, signOut } = this.props
      if (accessToken.length && checkTokenExpiration(tokenExpireTime)) {
        signOut()
      }
    }

    componentDidUpdate() {
      const { accessToken, tokenExpireTime, signOut } = this.props
      if (accessToken.length && checkTokenExpiration(tokenExpireTime)) {
        signOut()
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  Autologoutable.propTypes = {
    signOut: PropTypes.func.isRequired,
    accessToken: PropTypes.string.isRequired,
    tokenExpireTime: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }

  return Autologoutable
}

export default withAutologout
