import * as type from '../constants/servers'


const initialState = {
  server: 'pony',
  endpoints: [],
  currentEndpoint: '',
}

export default function servers(state = initialState, action) {
  switch (action.type) {
  case type.SELECT_SERVER:
    return { ...state, server: action.server }
  case type.SAVE_ENDPOINTS:
    return { ...state, endpoints: action.endpoints }
  case type.RESET_ENDPOINTS_DATA:
    return { ...state, endpoints: initialState.endpoints, currentEndpoint: initialState.currentEndpoint }
  case type.SET_CURRENT_ENDPOINT:
    return { ...state, currentEndpoint: action.currentEndpoint }

  case type.RESET_SERVERS:
    return initialState

  default:
    return state
  }
}
