const styles = () => ({
  loggerWrapper: {
    position: 'relative',
  },
  areaField: {
    width: '100%',
    margin: 0,
    verticalAlign: 'center',
  },
  clearButton: {
    height: 30,
    fontSize: '12px',
    zIndex: 99,
    position: 'absolute',
    right: 10,
    top: 10,
    background: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.44)',
    },
  },
})

export default styles
