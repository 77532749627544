import { createLogic } from 'redux-logic'
import * as type from '../constants/mezzoDevices'
import {
  syncFactoryDevicesSent, syncFactoryDevicesDone,
} from '../actions/mezzoDevices'
import factoryDevicesChannel from '../api/factoryDevicesChannel'

const subscribeFactoryDevicesLogic = createLogic({
  type: type.SUBSCRIBE_FACTORY_DEVICES,
  cancelType: type.CANCEL_SUBSCRIBE_FACTORY_DEVICES,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    factoryDevicesChannel({
      request: () => {
        dispatch(syncFactoryDevicesSent())
      },
      response: (data) => {
        window.store.dispatch(syncFactoryDevicesDone(data))
      },
    })
  },
})

export default subscribeFactoryDevicesLogic
