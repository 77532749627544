import { createLogic } from 'redux-logic'
import {
  RESTORE_UNIT_USERS,
} from '../constants/restoreUnitUsers'
import {
  restoreSent, restoreFailed,
} from '../actions/restoreUnitUsers'
import Api from '../api/restoreUnitUsers'
import endpoints from '../config/apiEndpoints'

const restoreUnitUsersLogic = createLogic({
  type: RESTORE_UNIT_USERS,

  transform({ getState, action }, next) {
    const { mezzoDevices: { selectedMezzo: { serial } } } = getState()
    const { servers: { server, currentEndpoint } } = getState()
    const { secrets: { accessToken } } = getState()
    const data = { serial_number: serial }
    const serverEndpoint = currentEndpoint || endpoints[action.server]
    next({
      ...action,
      accessToken,
      server,
      data,
      serverEndpoint,
    })
  },

  process({ action }, dispatch, done) {
    dispatch(restoreSent())
    Api.restoreUnitUsers(action.accessToken, action.serverEndpoint, action.data)
      .then(() => done())
      .catch((error) => {
        if (error) {
          dispatch(restoreFailed(error))
        }
      })
  },
})

export default restoreUnitUsersLogic
