import { createLogic } from 'redux-logic'
import {
  SET_PROGRAM,
  SET_CANCEL_PROGRAM,
} from '../constants/programOptions'
import { PROGRAM_OPTIONS_TIMEOUT } from '../constants/timeouts'
import convertOptionsToProgram from '../helpers/convertOptionsToProgram'

const submitProgramLogic = createLogic({
  type: SET_PROGRAM,
  cancelType: SET_CANCEL_PROGRAM,
  warnTimeout: PROGRAM_OPTIONS_TIMEOUT,

  transform({ getState, action }, next) {
    const { provisioning: { provisioningInfo } } = getState()
    const { form: { ProvisioningForm: { values: { connection_method: connectionType } } } } = getState()
    const programOptions = convertOptionsToProgram(provisioningInfo, connectionType)
    next({
      ...action,
      programOptions,
    })
  },

  process({ action }, dispatch, done) {
    global.programOptionsChannel.perform('update_program_options', { programOptions: action.programOptions })
    done()
  },
})

export default submitProgramLogic
