import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/es/storage'
import { rootReducer } from './reducers'

export const whitelist = ['servers', 'secrets']

const config = {
  key: 'root',
  storage,
  whitelist,
}

const configureReducer = history => persistReducer(config, rootReducer(history))

export default configureReducer
