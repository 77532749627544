import { CHANNEL_COMMANDS } from '../constants/channels'
import openCable from './cable'
import {
  updateCommandsStatus,
  setCommand,
  setCommandCurrentIndex,
  resetCommandCurrentIndex,
} from '../actions/commands'
import { updateConsoleInfo } from '../actions/console'

const commandsChannel = ({ request, response }) => {
  openCable(CHANNEL_COMMANDS).then((cable) => {
    const { mezzoDevices: { selectedMezzo: { identifier } } } = window.store.getState()
    const params = {
      channel: CHANNEL_COMMANDS,
      mezzo_device_identifier: identifier,
    }
    const subscription = cable.subscriptions.create(params, {
      connected() {
        request(this)
      },
      received(data) {
        window.store.dispatch(updateCommandsStatus(data))
        window.store.dispatch(updateConsoleInfo(`${data.status.toUpperCase()}: ${data.response}`.trim()))
        const { commands: { command, commandCurrentIndex } } = window.store.getState()
        const commandsSequence = command.split('\n').filter(el => el !== '')
        if (commandsSequence.length > 1 && commandsSequence.length !== commandCurrentIndex + 1) {
          window.store.dispatch(setCommandCurrentIndex())
          window.store.dispatch(setCommand())
        } else {
          window.store.dispatch(resetCommandCurrentIndex())
        }
        response(data)
      },
      disconnected: () => {
        global.commandsChannel = undefined
      },
    })
    global.commandsChannel = subscription
    return subscription
  })
}

export default commandsChannel
