import { createLogic } from 'redux-logic'
import * as type from '../constants/binaryCommands'
import {
  syncSent, syncDone, syncFailed,
} from '../actions/binaryCommands'
import binaryCommandsChannel from '../api/binaryCommandsChannel'
import convertErrorToConsole from '../helpers/convertErrorToConsole'

const subscribeBinaryCommandsLogic = createLogic({
  type: type.SUBSCRIBE,
  cancelType: type.CANCEL_SUBSCRIBE,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    binaryCommandsChannel({
      request: () => {
        dispatch(syncSent())
      },
      response: (data) => {
        const { error } = data
        if (error) {
          const fullError = convertErrorToConsole(error)
          window.store.dispatch(syncFailed(fullError))
        } else {
          window.store.dispatch(syncDone())
        }
      },
    })
  },
})

export default subscribeBinaryCommandsLogic
