import { createLogic } from 'redux-logic'
import {
  SET_PROVISIONING,
  SET_CANCEL_PROVISIONING,
} from '../constants/provisioning'
import {
  setSent, setFailed, setDone, updateProvisioningInfo,
} from '../actions/provisioning'
import Api from '../api/provisioning'
import convertProvisioningToBackend from '../helpers/convertProvisioningToBackend'
import endpoints from '../config/apiEndpoints'

const createProvisioningUnitLogic = createLogic({
  type: SET_PROVISIONING,
  cancelType: SET_CANCEL_PROVISIONING,

  transform({ getState, action }, next) {
    const { mezzoDevices: { selectedMezzo: { serial } } } = getState()
    const { provisioning: { longitude } } = getState()
    const { provisioning: { latitude } } = getState()
    const { form: { ProvisioningForm: { values } } } = getState()
    const { servers: { server, currentEndpoint } } = getState()
    const { secrets: { accessToken } } = getState()
    const data = convertProvisioningToBackend(values, serial, latitude, longitude, server)
    const serverEndpoint = currentEndpoint || endpoints[action.server]
    next({
      ...action,
      data,
      accessToken,
      server,
      serverEndpoint,
    })
  },

  process({ action }, dispatch, done) {
    Api.createProvisioningUnit(action.accessToken, action.data, action.serverEndpoint)
      .then((response) => {
        dispatch(setSent())
        dispatch(updateProvisioningInfo(response.data))
        dispatch(setDone())
      })
      .catch((error) => {
        if (error) {
          dispatch(setFailed(error))
        }
      })
      .then(() => done())
  },
})

export default createProvisioningUnitLogic
