import * as type from '../constants/console'

const initialState = {
  consoleInfo: '',
}

export default function console(state = initialState, action) {
  switch (action.type) {
  case type.UPDATE_CONSOLE_INFO:
    return { ...state, consoleInfo: state.consoleInfo.concat(`${action.consoleInfo}\n`) }
  case type.RESET_CONSOLE_INFO:
    return initialState
  default:
    return state
  }
}
