import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MezzoConnection from '../components/MezzoToolTabs/MezzoConnection/MezzoConnection'
import {
  collapseCloudPanel, collapseLocalPanel, clearTimestamp, subscribeFactoryDevices, unsubscribeFactoryDevices,
} from '../actions/mezzoDevices'
import {
  switchType,
} from '../actions/connection'
import {
  unsubscribeProgramOptions,
} from '../actions/programOptions'
import {
  signOut,
} from '../actions/signIn'

let enhance = MezzoConnection

const mapStateToProps = (state) => {
  const { type } = state.connection
  const { accessToken, tokenExpireTime } = state.secrets
  const { cloudConnection, selectedMezzo } = state.mezzoDevices
  const { isSignInPopupOpen } = state.signIn
  return {
    connectivity: type,
    accessToken,
    tokenExpireTime,
    cloudConnection,
    selectedMezzo,
    isSignInPopupOpen,
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  chooseConnectivity: switchType,
  unsubscribeProgramOptions,
  collapseCloudPanel,
  collapseLocalPanel,
  signOut,
  clearTimestamp,
  subscribeFactoryDevices,
  unsubscribeFactoryDevices,
}, dispatch)

enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)

const MezzoConnectionEnhanced = enhance

export default MezzoConnectionEnhanced
