import {
  SET_PROVISIONING,
  SET_SENT_PROVISIONING,
  SET_FAILED_PROVISIONING,
  SET_DONE_PROVISIONING,
  UPDATE_PROVISIONING_INFO,

  SAVE_LONGITUDE,
  SAVE_LATTITUDE,
  RESET_PROVISIONING,
} from '../constants/provisioning'

export const set = () => ({
  type: SET_PROVISIONING,
})

export const setSent = () => ({
  type: SET_SENT_PROVISIONING,
})

export const setDone = () => ({
  type: SET_DONE_PROVISIONING,
})
export const setFailed = message => ({
  type: SET_FAILED_PROVISIONING,
  message,
})
export const updateProvisioningInfo = info => ({
  type: UPDATE_PROVISIONING_INFO,
  info,
})

export const saveLongitude = longitude => ({
  type: SAVE_LONGITUDE,
  longitude,
})

export const saveLatitude = latitude => ({
  type: SAVE_LATTITUDE,
  latitude,
})

export const resetProvisioning = () => ({
  type: RESET_PROVISIONING,
})
