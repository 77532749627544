const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 25,
    boxShadow: 'none',
  },
  componentWrapper: {
    maxWidth: 900,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomToolsWrapper: {
    marginTop: 20,
  },
  '@media (max-width: 860px)': {
    componentWrapper: {
      flexDirection: 'column',
    },
  },
})

export default styles
