import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  TextField,
  FormHelperText,
  withStyles,
} from '@material-ui/core/index'


import styles from './EditTextField.style'


class EditTextField extends React.PureComponent {
  createTextField =() => {
    const {
      input,
      placeholder,
      disabled,
    } = this.props
    return (
      <TextField
        onChange={(event, index, value) => input.onChange(value)}
        InputProps={{
          ...input,
        }}
        placeholder={placeholder}
        variant="outlined"
        disabled={disabled}
      />
    )
  }

  render() {
    const {
      meta: { touched, error },
      classes,
    } = this.props
    return (
      <FormControl className={classes.field}>
        { this.createTextField() }
        {touched && error && (
          <FormHelperText id="component-error-text" className={classes.text}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    )
  }
}

EditTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

EditTextField.defaultProps = {
  disabled: false,
}

export default (withStyles(styles)(EditTextField))
