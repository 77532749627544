import { createLogic } from 'redux-logic'
import { SIGN_OUT } from '../constants/signIn'
import { whitelist } from '../reducer'
import resetPersistent from '../helpers/resetPersistent'
import {
  switchType as chooseConnectivity,
} from '../actions/connection'
import {
  resetMezzoDevices,
} from '../actions/mezzoDevices'
import {
  resetProvisioning,
} from '../actions/provisioning'
import {
  resetSignIn,
} from '../actions/signIn'
import {
  resetProgramOptions,
} from '../actions/programOptions'

const signOutLogic = createLogic({
  type: SIGN_OUT,

  process(_, dispatch, done) {
    whitelist.forEach(el => dispatch(resetPersistent(el)))
    dispatch(chooseConnectivity('localComms'))
    dispatch(resetMezzoDevices())
    dispatch(resetProgramOptions())
    dispatch(resetProvisioning())
    dispatch(resetSignIn())
    if (global.cloudCable) {
      global.cloudCable.disconnect()
    }
    done()
  },
})

export default signOutLogic
