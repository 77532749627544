import { CHANNEL_FACTORY_DEVICES } from '../constants/channels'
import openCable from './cable'

const factoryDevicesChannel = ({ request, response }) => {
  openCable(CHANNEL_FACTORY_DEVICES).then((cable) => {
    const { servers: { server, endpoints, currentEndpoint } } = window.store.getState()
    const params = {
      channel: CHANNEL_FACTORY_DEVICES,
      api_url: currentEndpoint || `https://${endpoints[server]}`,
    }
    const subscription = cable.subscriptions.create(params, {
      connected() {
        request(this)
      },
      received(data) {
        response(data)
      },
      disconnected: () => {
        global.factoryDevicesChannel = undefined
      },
    })
    global.factoryDevicesChannel = subscription
    return subscription
  })
}

export default factoryDevicesChannel
