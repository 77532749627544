import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  withStyles,
} from '@material-ui/core'
import Wrapper from '../../../Common/Wrapper/Wrapper'
import TextField from '../../../Common/TextField/TextField'
import styles from './ProvisioningForm.style'
import ConnectionType from './ConnectionType/ConnectionType'

class ProvisioningForm extends React.Component {
  render() {
    const {
      classes,
      submitting,
      submit,
      provisioningInfo,
    } = this.props
    return (
      <Wrapper className={classes.root}>
        <form>
          <ConnectionType />
          <Wrapper className={classes.title}>Create unit on sphere and receive Unit Token</Wrapper>
          <Wrapper className={classes.buttonWrapper}>
            <TextField
              label="Unit Token"
              margin="normal"
              selectedOption={provisioningInfo.unit_device ? provisioningInfo.unit_device.token : ''}
              selectOption={() => {}}
              disabled={false}
            />
            <Button
              className={classes.button}
              disabled={submitting}
              variant="outlined"
              color="primary"
              onClick={submit}
            >
              Get
            </Button>
          </Wrapper>
        </form>
      </Wrapper>
    )
  }
}

ProvisioningForm.propTypes = {
  classes: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  provisioningInfo: PropTypes.shape({
    endpoint: PropTypes.shape({
      ip: PropTypes.string,
      port: PropTypes.number,
    }),
    unit_device: PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      password: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      status: PropTypes.string,
      token: PropTypes.string,
    }),
    unit_users: PropTypes.arrayOf(PropTypes.shape),
    program_options: PropTypes.arrayOf(PropTypes.shape),
    time_location: PropTypes.shape({
      id: PropTypes.number,
      latitude: PropTypes.string,
      longitude: PropTypes.string,
    }),
  }),
}

ProvisioningForm.defaultProps = {
  provisioningInfo: {},
}


export default withStyles(styles)(ProvisioningForm)
