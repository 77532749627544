const styles = () => ({
  root: {
    marginTop: 20,
  },
  tools: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  title: {
    width: '100%',
    textAlign: 'center',
    color: '#000',
    textDecoration: 'underline',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    width: '48%',
  },
  button: {
    margin: 0,
    width: 70,
    height: 56,
  },
  binaryCommandWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    width: '48%',
  },
  binaryCommandButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  commandsButton: {
    margin: 0,
    width: 70,
    height: 75,
  },
  areaField: {
    width: '100%',
    margin: 0,
    verticalAlign: 'center',
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  mezzoButtons: {
    margin: '0 20px 40px 0',
    textTransform: 'none',
    fontSize: '14px',
  },
  checkboxWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  checkbox: {
    padding: '0 0 0 5px',
  },
  accordion: {
    background: 'none',
    boxShadow: 'none',
    border: 'none',
    marginBottom: 30,
  },
  dropTools: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    cursor: 'pointer',
    padding: '8px 0',
  },
  '@media (max-width: 640px)': {
    tools: {
      flexDirection: 'column',
    },
    buttonWrapper: {
      width: '100%',
    },
    binaryCommandWrapper: {
      width: '100%',
    },
    buttonsWrapper: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    mezzoButtons: {
      width: '100%',
      margin: '10px 0 0 0',
    },
  },
})

export default styles
