import * as type from '../constants/connection'

const initialState = {
  type: '',
  cloud: {
    opened: false,
  },
  local: {
    opened: false,
  },
}

export default function connection(state = initialState, action) {
  switch (action.type) {
  case type.CLOUD_CONNECTION_OPEN:
    return { ...state, cloud: { opened: true } }
  case type.LOCAL_CONNECTION_OPEN:
    return { ...state, local: { opened: true } }
  case type.CLOUD_CONNECTION_CLOSE:
    return { ...state, cloud: { opened: false } }
  case type.LOCAL_CONNECTION_CLOSE:
    return { ...state, local: { opened: false } }
  case type.SWITCH_CONNECTION_TYPE:
    return {
      ...state,
      type: action.connectionType,
    }
  case type.RESET_CONNECTION:
    return initialState
  default:
    return state
  }
}
