import subscribeMezzoDevicesLogic from './subscribeMezzoDevices'
import subscribeProgramOptionsLogic from './subscribeProgramOptions'
import subscribeCommandsLogic from './subscribeCommands'
import subscribeFactoryDevicesLogic from './subscribeFactoryDevices'
import subscribeRestoreUnitUsersLogic from './subscribeRestoreUnitUsers'
import fetchProgramOptionsLogic from './fetchProgramOptions'
import submitProgramOptionsLogic from './submitProgramOptions'
import switchConnectionTypeLogic from './switchConnectionType'
import unsubscribeMezzoDevicesLogic from './unsubscribeMezzoDevices'
import unsubscribeProgramOptionsLogic from './unsubscribeProgramOptions'
import unsubscribeCommands from './unsubscribeCommands'
import unsubscribeFactoryDevices from './unsubscribeFactoryDevices'
import unsubscribeRestoreUnitUsers from './unsubscribeRestoreUnitUsers'
import createProvisioningUnitLogic from './createProvisioningUnit'
import submitProgramLogic from './submitProgram'
import submitDetectorLogic from './submitDetector'
import submitPendantLogic from './submitPendant'
import submitCommandLogic from './submitCommand'
import signInLogic from './signIn'
import signOutLogic from './signOut'
import restoreUnitUsers from './restoreUnitUsers'
import submitBinaryCommandLogic from './submitBinaryCommand'
import subscribeBinaryCommandsLogic from './subscribeBinaryCommands'
import unsubscribeBinaryCommandsLogic from './unsubscribeBinaryCommands'

export default [
  subscribeMezzoDevicesLogic,
  subscribeProgramOptionsLogic,
  subscribeCommandsLogic,
  subscribeFactoryDevicesLogic,
  subscribeRestoreUnitUsersLogic,
  fetchProgramOptionsLogic,
  submitProgramOptionsLogic,
  switchConnectionTypeLogic,
  unsubscribeMezzoDevicesLogic,
  unsubscribeProgramOptionsLogic,
  unsubscribeCommands,
  unsubscribeFactoryDevices,
  unsubscribeRestoreUnitUsers,
  createProvisioningUnitLogic,
  submitProgramLogic,
  submitDetectorLogic,
  submitPendantLogic,
  submitCommandLogic,
  signInLogic,
  signOutLogic,
  restoreUnitUsers,
  submitBinaryCommandLogic,
  subscribeBinaryCommandsLogic,
  unsubscribeBinaryCommandsLogic,
]
