import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { reduxForm, submit } from 'redux-form'
import ProvisioningForm from '../../components/MezzoToolTabs/Provisioning/ProvisioningForm/ProvisioningForm'
import { set } from '../../actions/provisioning'

const mapStateToProps = (state) => {
  const { serialNumber, connection_method, provisioningInfo } = state.provisioning
  return {
    provisioningInfo,
    serialNumber,
    initialValues: {
      connection_method,
    },
  }
}

const mapDispatchToProps = parentDispatch => bindActionCreators({
  submit: dispatch => dispatch(submit('ProvisioningForm')),
}, parentDispatch)

let enhance = reduxForm({
  form: 'ProvisioningForm',
  onSubmit: () => {
    window.store.dispatch(set())
  },
})(ProvisioningForm)


enhance = connect(mapStateToProps, mapDispatchToProps)(enhance)


const ProvisioningFormEnhanced = enhance

export default ProvisioningFormEnhanced
