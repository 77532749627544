const styles = () => ({
  mezzoMonitoringWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  mezzoMonitoring: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    color: '#000',
    marginTop: 12,
    marginBottom: 12,
  },
  mezzoMonitoringButton: {
    width: 70,
    height: 40,
    margin: '0 20px',
  },
  selectWrapper: {
    width: 200,
    display: 'flex',
    alignItems: 'center',
  },
  '@media (max-width: 640px)': {
    mezzoMonitoring: {
      flexDirection: 'column',
    },
    mezzoMonitoringButton: {
      width: '100%',
    },
    selectWrapper: {
      maxWidth: '100%',
      width: '100%',
      justifyContent: 'space-between',
    },
  },
})

export default styles
