import { CHANNEL_PROGRAM_OPTIONS } from '../constants/channels'
import openCable from './cable'
import { updateConsoleInfo } from '../actions/console'
import convertDataToConsole from '../helpers/convertDataToConsole'

const programOptionChannel = ({ request, response }) => {
  openCable(CHANNEL_PROGRAM_OPTIONS).then((cable) => {
    const { mezzoDevices: { selectedMezzo: { identifier } } } = window.store.getState()
    const params = {
      channel: CHANNEL_PROGRAM_OPTIONS,
      mezzo_device_identifier: identifier,
    }
    const subscription = cable.subscriptions.create(params, {
      connected() {
        request(this)
      },
      received(data) {
        const { progress: { step, total_steps }, error } = data
        const isFinished = error || step === total_steps
        if (error) {
          window.store.dispatch(updateConsoleInfo(`ERROR: ${error.msg}, code: ${error.code}`))
        }
        window.store.dispatch(updateConsoleInfo(convertDataToConsole(data.programOptions)))
        response(data, isFinished)
      },
      disconnected: () => {
        global.programOptionsChannel = undefined
      },
    })
    global.programOptionsChannel = subscription
    return subscription
  })
}

export default programOptionChannel
