const styles = () => ({
  root: {
    minHeight: 70,
    justifyContent: 'space-between',
    marginRight: 5,
    maxWidth: 200,
  },
  title: {
    width: '40%',
    fontSize: '1rem',
  },
  '@media (max-width: 860px)': {
    root: {
      width: '60%',
    },
  },
  '@media (max-width: 450px)': {
    root: {
      maxWidth: '100%',
    },
  },
})

export default styles
