import React from 'react'
import PropTypes from 'prop-types'
import {
  Tabs,
  Tab,
  Tooltip,
} from '@material-ui/core/'
import { withStyles } from '@material-ui/core/styles'
import styles from './MezzoToolTabs.style'
import Wrapper from '../Common/Wrapper/Wrapper'
import Programming from '../../containers/Programming'
import Provisioning from '../../containers/Provisioning/Provisioning'
import Rescue from '../../containers/Rescue'
import checkTokenExpiration from '../../helpers/checkTokenExpiration'

class MezzoToolTabs extends React.Component {
  state = {
    value: 0,
  }

  static getDerivedStateFromProps(props, state) {
    if (checkTokenExpiration(props.tokenExpireTime) && state.value === 1) {
      return {
        value: 0,
      }
    }
    return null
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  createTabs = () => {
    const { value } = this.state
    const
      {
        classes,
        history,
        tokenExpireTime,
      } = this.props
    return (
      <Wrapper className={classes.tabsWrapper}>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleChange}
          variant="fullWidth"
        >
          <Tab label="Programming" className={classes.tab} />
          <Tab
            className={classes.tab}
            style={{ pointerEvents: 'auto' }}
            disabled={checkTokenExpiration(tokenExpireTime)}
            icon={checkTokenExpiration(tokenExpireTime) ? (
              <Tooltip classes={{ tooltip: classes.tooltip }} title="Sign in to use Provisioning">
                <span>Provisioning</span>
              </Tooltip>
            )
              : <span>Provisioning</span>
            }
          />
          <Tab label="Rescue" className={classes.tab} />
        </Tabs>
        {value === 0 && <Programming history={history} />}
        {value === 1 && <Provisioning history={history} />}
        {value === 2 && <Rescue history={history} />}
      </Wrapper>
    )
  }

  render() {
    const
      {
        classes,
      } = this.props
    return (
      <Wrapper className={classes.root}>
        {this.createTabs()}
      </Wrapper>

    )
  }
}

MezzoToolTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  tokenExpireTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
}

MezzoToolTabs.defaultProps = {
  history: null,
}


export default withStyles(styles)(MezzoToolTabs)
