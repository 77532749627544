import * as type from '../constants/binaryCommands'

const initialState = {
  errorMessage: '',
  binaryCommand: '',
  crcValue: true,
  binaryCommandStatus: '',
}

export default function binaryCommands(state = initialState, action) {
  switch (action.type) {
  case type.SELECT_BINARY_COMMAND:
    return { ...state, binaryCommand: action.binaryCommand }
  case type.SELECT_CRC_VALUE:
    return { ...state, crcValue: action.crcValue }

  case type.UPDATE_BINARY_COMMANDS_STATUS:
    return { ...state, binaryCommandStatus: action.binaryCommandStatus }

  case type.SYNC_FAILED_BINARY_COMMANDS:
    return { ...state, errorMessage: action.message }

  default:
    return state
  }
}
