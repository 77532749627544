import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import {
  ListItem,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from './EditText.style'
import EditTextField from './EditTextField'

class EditText extends React.Component {
  render() {
    const {
      classes,
      title,
      name,
      placeholder,
      ...other
    } = this.props
    return (
      <ListItem className={classes.root}>
        <Typography className={classes.title}>{title}</Typography>
        <Field
          className={classes.field}
          name={name}
          component={EditTextField}
          placeholder={placeholder}
          {...other}
        />
      </ListItem>
    )
  }
}

EditText.defaultProps = {
  hint: false,
}

EditText.propTypes = {
  classes: PropTypes.object.isRequired,
  hint: PropTypes.bool,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
}

export default (withStyles(styles)(EditText))
