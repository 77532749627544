const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 25,
    boxShadow: 'none',
  },
  componentWrapper: {
    maxWidth: 900,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolsWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  monitoringWrapper: {
    padding: '10px 0 30px 0',
    margin: '30px 0 10px 0',
    borderTop: '1px solid rgba(0, 0, 0, 0.4)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
  },
  '@media (max-width: 860px)': {
    componentWrapper: {
      flexDirection: 'column',
    },
  },
})

export default styles
