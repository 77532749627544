import { createLogic } from 'redux-logic'
import { CHANNEL_FACTORY_DEVICES } from '../constants/channels'
import { UNSUBSCRIBE_FACTORY_DEVICES } from '../constants/mezzoDevices'


const unsubscribeFactoryDevices = createLogic({
  type: UNSUBSCRIBE_FACTORY_DEVICES,

  process(_, dispatch, done) {
    if (global.localCable) {
      global.localCable.subscriptions.subscriptions.forEach(
        sub => sub.identifier.includes(CHANNEL_FACTORY_DEVICES) && sub.unsubscribe(),
      )
    }
    if (global.cloudCable) {
      global.cloudCable.subscriptions.subscriptions.forEach(
        sub => sub.identifier.includes(CHANNEL_FACTORY_DEVICES) && sub.unsubscribe(),
      )
    }
    done()
  },
})

export default unsubscribeFactoryDevices
