import { createLogic } from 'redux-logic'
import {
  SET_PROGRAM_OPTIONS,
  SET_CANCEL_PROGRAM_OPTIONS,
} from '../constants/programOptions'
import { PROGRAM_OPTIONS_TIMEOUT } from '../constants/timeouts'

const submitProgramOptionsLogic = createLogic({
  type: SET_PROGRAM_OPTIONS,
  cancelType: SET_CANCEL_PROGRAM_OPTIONS,
  warnTimeout: PROGRAM_OPTIONS_TIMEOUT,

  transform({ getState, action }, next) {
    const { programOptions: { selectedOption, selectedOptionValue } } = getState()
    const programOptions = [{ number: parseInt(selectedOption, 10), value: selectedOptionValue }]

    next({
      ...action,
      programOptions,
    })
  },

  process({ action }, dispatch, done) {
    global.programOptionsChannel.perform('update_program_options', { programOptions: action.programOptions })
    done()
  },
})

export default submitProgramOptionsLogic
