import { createLogic } from 'redux-logic'
import * as type from '../constants/restoreUnitUsers'
import {
  syncSent, syncDone, syncFailed, restoreDone, restoreFailed,
} from '../actions/restoreUnitUsers'
import restoreUnitUsersChannel from '../api/restoreUnitUsersChannel'

const subscribeRestoreUnitUsersLogic = createLogic({
  type: type.SUBSCRIBE,
  cancelType: type.CANCEL_SUBSCRIBE,
  latest: true,
  warnTimeout: 0,

  process(_, dispatch) {
    restoreUnitUsersChannel({
      request: () => {
        dispatch(syncSent())
      },
      response: (data) => {
        const { error, status } = data
        if (status === 'success') {
          window.store.dispatch(restoreDone('Unit users restored successfully'))
        } else if (status === 'failure') {
          window.store.dispatch(restoreFailed('Failure to restore unit users'))
        }
        if (error) {
          window.store.dispatch(syncFailed(error))
        } else {
          window.store.dispatch(syncDone())
        }
      },
    })
  },
})

export default subscribeRestoreUnitUsersLogic
