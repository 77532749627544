import { createLogic } from 'redux-logic'
import { CHANNEL_RESTORE_UNIT_USERS } from '../constants/channels'
import { UNSUBSCRIBE } from '../constants/restoreUnitUsers'


const unsubscribeRestoreUnitUsers = createLogic({
  type: UNSUBSCRIBE,

  process(_, dispatch, done) {
    if (global.localCable) {
      global.localCable.subscriptions.subscriptions.forEach(
        sub => sub.identifier.includes(CHANNEL_RESTORE_UNIT_USERS) && sub.unsubscribe(),
      )
    }
    if (global.cloudCable) {
      global.cloudCable.subscriptions.subscriptions.forEach(
        sub => sub.identifier.includes(CHANNEL_RESTORE_UNIT_USERS) && sub.unsubscribe(),
      )
    }
    done()
  },
})

export default unsubscribeRestoreUnitUsers
