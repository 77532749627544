import { createLogic } from 'redux-logic'
import {
  SYNC_PROGRAM_OPTIONS,
  SYNC_CANCEL_PROGRAM_OPTIONS,
} from '../constants/programOptions'

const fetchProgramOptionsLogic = createLogic({
  type: SYNC_PROGRAM_OPTIONS,
  cancelType: SYNC_CANCEL_PROGRAM_OPTIONS,

  process(_, dispatch, done) {
    const { programOptions: { selectedOption } } = window.store.getState()
    const { programOptions: { selectedOptions } } = window.store.getState()
    let optionsData
    if (selectedOption) {
      optionsData = { numbers: [parseInt(selectedOption, 10)] }
    } else {
      optionsData = { numbers: selectedOptions }
    }
    global.programOptionsChannel.perform('get_program_options', optionsData)
    done()
  },
})

export default fetchProgramOptionsLogic
