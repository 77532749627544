import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import Wrapper from '../../Common/Wrapper/Wrapper'
import styles from './Rescue.style'
import BottomTools from './BottomTools/BottomTools'
import Tools from '../../../containers/Tools'
import AdvancedTools from '../../../containers/AdvancedTools'
import Logger from '../../../containers/Logger'
import MezzoConnection from '../../../containers/MezzoConnection'

class Rescue extends React.Component {
  componentDidMount() {
    const { subscribeRestoreUnitUsers } = this.props
    subscribeRestoreUnitUsers()
  }

  componentWillUnmount() {
    const { unsubscribeRestoreUnitUsers } = this.props
    unsubscribeRestoreUnitUsers()
  }

  render() {
    const {
      classes,
      connectivity,
      selectedMezzo,
      restoreUnitUsers,
      restoreUnitUsersInProgress,
    } = this.props
    return (
      <Paper className={classes.root}>
        <MezzoConnection />
        <Wrapper className={classes.bottomToolsWrapper}>
          <BottomTools
            selectedMezzo={selectedMezzo}
            restoreUnitUsers={restoreUnitUsers}
            restoreUnitUsersInProgress={restoreUnitUsersInProgress}
            connectivity={connectivity}
          />
        </Wrapper>
        <Wrapper className={classes.toolsWrapper}>
          <Tools />
          <AdvancedTools />
        </Wrapper>
        <Logger />
      </Paper>
    )
  }
}

Rescue.propTypes = {
  classes: PropTypes.object.isRequired,
  connectivity: PropTypes.string.isRequired,
  selectedMezzo: PropTypes.shape({
    serial: PropTypes.string,
    identifier: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  subscribeRestoreUnitUsers: PropTypes.func.isRequired,
  unsubscribeRestoreUnitUsers: PropTypes.func.isRequired,
  restoreUnitUsers: PropTypes.func.isRequired,
  restoreUnitUsersInProgress: PropTypes.bool.isRequired,
}

Rescue.defaultProps = {
  selectedMezzo: PropTypes.shape({
    serial: PropTypes.string,
    identifier: PropTypes.string,
  }),
  history: null,
}

export default withStyles(styles)(Rescue)
