import { createLogic } from 'redux-logic'

import { localClose, cloudClose } from '../actions/connection'
import { SWITCH_CONNECTION_TYPE } from '../constants/connection'

const switchConnectionType = createLogic({
  type: SWITCH_CONNECTION_TYPE,

  process(_, dispatch, done) {
    const { store } = window
    const { connection: { type } } = store.getState()

    if (type === 'cloud') {
      const { connection: { local: { opened } } } = store.getState()
      if (opened) {
        global.localCable.disconnect()
        store.dispatch(localClose())
      }
    } else {
      const { connection: { cloud: { opened } } } = store.getState()
      if (opened) {
        global.cloudCable.disconnect()
        store.dispatch(cloudClose())
      }
    }
    done()
  },
})

export default switchConnectionType
