import React from 'react'
import { Button, TextField as MaterialTextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import styles from './Logger.style'
import Wrapper from '../../../Common/Wrapper/Wrapper'

class Logger extends React.Component {
  constructor(props) {
    super(props)
    this.consoleRef = React.createRef()
  }

  componentDidUpdate() {
    this.consoleRef.current.scrollTop = this.consoleRef.current.scrollHeight
  }

  render() {
    const {
      classes,
      consoleInfo,
      resetConsoleInfo,
    } = this.props
    return (
      <Wrapper className={classes.loggerWrapper}>
        <Button
          className={classes.clearButton}
          variant="contained"
          color="primary"
          onClick={() => {
            resetConsoleInfo()
          }}
        >
          Clear console
        </Button>
        <MaterialTextField
          className={classes.areaField}
          inputRef={this.consoleRef}
          multiline
          margin="normal"
          InputProps={{
            readOnly: true,
          }}
          rows="15"
          variant="filled"
          label="Mezzo response"
          value={consoleInfo}
        />
      </Wrapper>
    )
  }
}

Logger.propTypes = {
  classes: PropTypes.object.isRequired,
  consoleInfo: PropTypes.string.isRequired,
  resetConsoleInfo: PropTypes.func.isRequired,
}


export default withStyles(styles)(Logger)
