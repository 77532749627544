const styles = () => ({
  main: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 9999,
    backgroundColor: 'none',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    paddingBottom: 30,
  },
  loginForm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '60px 25px',
    borderRadius: '10px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    position: 'relative',
    width: 415,
    backgroundColor: '#fff',
  },
  appVersion: {
    position: 'absolute',
    right: 10,
    bottom: 10,
    fontSize: '12px',
  },
  wrapper: {
    marginTop: 30,
  },
  typography: {
    marginBottom: 20,
  },
  textFormControl: {
    margin: '10px 0',
  },
  group: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 20,
  },
  textField: {
    marginBottom: 10,
  },
  errorMessage: {
    position: 'absolute',
    bottom: 10,
    color: 'red',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '32px',
  },
  buildIcon: {
    marginLeft: 15,
    transform: 'rotate(90deg)',
    fontSize: '30px',
  },
  button: {
    width: 150,
    height: 40,
  },
  formControlSelect: {
    width: 120,
    marginBottom: 20,
  },
  loader: {
    marginTop: 38,
    marginBottom: 20,
  },
  label: {
    marginLeft: -12,
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'block',
    width: 50,
    height: 50,
    fontSize: 0,
    zIndex: 10000,
    cursor: 'pointer',
    '&:before': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 3,
      height: 20,
      backgroundColor: '#000',
      transform: 'rotate(45deg) translate(-50%, -50%)',
      transformOrigin: 'top left',
      content: "''",
    },
    '&:after': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 3,
      height: 20,
      backgroundColor: '#000',
      transform: 'rotate(-45deg) translate(-50%, -50%)',
      transformOrigin: 'top left',
      content: "''",
    },
  },
  '@media (max-width: 640px)': {
    loginForm: {
      width: '80%',
    },
  },
})

export default styles
