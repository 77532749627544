const endpoints = {
  development: 'localhost:3000',
  cloudProduction: 'mezzo-api.nesscs.com',
  localComms: 'localhost:3002',
  dev: 'dev-api.nesscs.com',
  pony: 'pony-api.nesscs.com',
  ocelot: 'ocelot-api.nesscs.com',
  smartlink: 'smartlink-api.nesscs.com',
}

export default endpoints
