import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Cloud from '../components/MezzoToolTabs/MezzoConnection/Cloud/Cloud'
import {
  sync, syncDone, expandCloudPanel, collapseCloudPanel, selectMezzo,
  update, unsubscribe, setCloudConnectInfo, clearTimestamp,
} from '../actions/mezzoDevices'
import {
  subscribeProgramOptions,
  unsubscribeProgramOptions,
} from '../actions/programOptions'
import {
  subscribeCommands,
  unsubscribeCommands,
} from '../actions/commands'
import {
  subscribeBinaryCommands,
  unsubscribeBinaryCommands,
} from '../actions/binaryCommands'
import { openSignInPopup } from '../actions/signIn'

const mapStateToProps = (state) => {
  const { type } = state.connection
  const { tokenExpireTime } = state.secrets
  const {
    mezzoDevices: cloudDevices, selectedMezzo, isCloudPanelExpanded, selectedCheckbox, cloudConnectInfo, timestamp,
  } = state.mezzoDevices
  return {
    connectivity: type,
    cloudDevices,
    selectedMezzo,
    isCloudPanelExpanded,
    selectedCheckbox,
    cloudConnectInfo,
    timestamp,
    tokenExpireTime,
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  sync,
  syncDone,
  expandCloudPanel,
  collapseCloudPanel,
  selectMezzo,
  update,
  subscribeProgramOptions,
  unsubscribeProgramOptions,
  subscribeCommands,
  unsubscribeCommands,
  setCloudConnectInfo,
  refresh: sync,
  tryAgain: sync,
  unsubscribe,
  clearTimestamp,
  subscribeBinaryCommands,
  unsubscribeBinaryCommands,
  openSignInPopup,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Cloud)
