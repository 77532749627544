export const SYNC_SENT_RESTORE_UNIT_USERS = '@@restoreUnitUsers/syncSent'
export const SYNC_FAILED_RESTORE_UNIT_USERS = '@@restoreUnitUsers/syncFailed'
export const SYNC_DONE_RESTORE_UNIT_USERS = '@@restoreUnitUsers/syncDone'

export const SUBSCRIBE = '@@restoreUnitUsers/subscribe'
export const UNSUBSCRIBE = '@@restoreUnitUsers/unsubscribe'
export const CANCEL_SUBSCRIBE = '@@restoreUnitUsers/cancel_subscribe'

export const RESTORE_UNIT_USERS = '@@restoreUnitUsers/restore'
export const RESTORE_UNIT_USERS_SENT = '@@restoreUnitUsers/restoreSent'
export const RESTORE_UNIT_USERS_FAILED = '@@restoreUnitUsers/restoreFailed'
export const RESTORE_UNIT_USERS_DONE = '@@restoreUnitUsers/restoreDone'
