const styles = () => ({
  text: {
    color: '#f44336',
    position: 'absolute',
    top: 50,
  },
  icon: {
    fontSize: 40,
    position: 'absolute',
    top: 5,
    left: 5,
  },
  '@media (max-width: 640px)': {
    field: {
      maxWidth: '100%',
      width: '100%',
    },
  },
})

export default styles
