import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Logger from '../components/MezzoToolTabs/Programming/Logger/Logger'
import { resetConsoleInfo } from '../actions/console'

const mapStateToProps = (state) => {
  const { consoleInfo } = state.console
  return {
    consoleInfo,
  }
}
const mapDispatchToProps = dispatch => bindActionCreators({
  resetConsoleInfo,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Logger)
