import { createLogic } from 'redux-logic'
import {
  SET_DETECTOR_OPTIONS,
  SET_CANCEL_TWR_OPTIONS,
} from '../constants/programOptions'
import { PROGRAM_OPTIONS_TIMEOUT } from '../constants/timeouts'

const submitDetectorLogic = createLogic({
  type: SET_DETECTOR_OPTIONS,
  cancelType: SET_CANCEL_TWR_OPTIONS,
  warnTimeout: PROGRAM_OPTIONS_TIMEOUT,

  transform({ getState, action }, next) {
    const { form: { MezzoMonitoringFormDetector: { values: { detector, TXID } } } } = getState()
    const optionValue = `${TXID}0110${detector}`
    const programOptions = [{ number: 0, value: optionValue }]

    next({
      ...action,
      programOptions,
    })
  },

  process({ action }, dispatch, done) {
    global.programOptionsChannel.perform('update_program_options', { programOptions: action.programOptions })
    done()
  },
})

export default submitDetectorLogic
