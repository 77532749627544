import { CHANNEL_RESTORE_UNIT_USERS } from '../constants/channels'
import openCable from './cable'
import { updateConsoleInfo } from '../actions/console'

const restoreUnitUsersChannel = ({ request, response }) => {
  openCable(CHANNEL_RESTORE_UNIT_USERS).then((cable) => {
    const { mezzoDevices: { selectedMezzo: { serial } } } = window.store.getState()
    const params = {
      channel: CHANNEL_RESTORE_UNIT_USERS,
      serial_number: serial,
    }
    const subscription = cable.subscriptions.create(params, {
      connected() {
        request(this)
      },
      received(data) {
        window.store.dispatch(updateConsoleInfo(data))
        response(data)
      },
      disconnected: () => {
        global.restoreUnitUsersChannel = undefined
      },
    })
    global.restoreUnitUsersChannel = subscription
    return subscription
  })
}

export default restoreUnitUsersChannel
