import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Wrapper from '../../Common/Wrapper/Wrapper'
import styles from './MezzoConnection.style'
import LocalComms from '../../../containers/LocalComms'
import Cloud from '../../../containers/Cloud'
import ConnectivityRadioGroup from './ConnectivityRadioGroup/ConnectivityRadioGroup'
import MezzoInfo from './MezzoInfo/MezzoInfo'
import Logout from '../../../containers/Logout'
import ServersSelect from '../../../containers/ServersSelect'
import checkTokenExpiration from '../../../helpers/checkTokenExpiration'

class MezzoConnection extends React.Component {
  state = {
    screenSize: window.innerWidth,
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.setState({
      screenSize: window.innerWidth,
    })
  }

  render() {
    const {
      classes,
      connectivity,
      selectedMezzo,
      collapseCloudPanel,
      collapseLocalPanel,
      chooseConnectivity,
      unsubscribeProgramOptions,
      history,
      clearTimestamp,
      subscribeFactoryDevices,
      unsubscribeFactoryDevices,
      isSignInPopupOpen,
      tokenExpireTime,
    } = this.props
    const { screenSize } = this.state
    return (
      <>
        {!checkTokenExpiration(tokenExpireTime) && (<Logout history={history} />)}
        <ConnectivityRadioGroup
          connectivity={connectivity}
          collapseCloudPanel={collapseCloudPanel}
          collapseLocalPanel={collapseLocalPanel}
          chooseConnectivity={chooseConnectivity}
          unsubscribeProgramOptions={unsubscribeProgramOptions}
          clearTimestamp={clearTimestamp}
          subscribeFactoryDevices={subscribeFactoryDevices}
          unsubscribeFactoryDevices={unsubscribeFactoryDevices}
        />
        <Wrapper className={classes.componentWrapper}>
          {screenSize <= 860 && (
            connectivity === 'localComms' ? <LocalComms /> : <Cloud />
          )}
          {screenSize > 860 && (
            <>
              <LocalComms />
              <Cloud />
            </>
          )}
        </Wrapper>
        <MezzoInfo selectedMezzo={selectedMezzo} />
        {isSignInPopupOpen && (
          <ServersSelect />
        )}
      </>
    )
  }
}

MezzoConnection.propTypes = {
  classes: PropTypes.object.isRequired,
  chooseConnectivity: PropTypes.func.isRequired,
  connectivity: PropTypes.string.isRequired,
  collapseCloudPanel: PropTypes.func.isRequired,
  collapseLocalPanel: PropTypes.func.isRequired,
  unsubscribeProgramOptions: PropTypes.func.isRequired,
  clearTimestamp: PropTypes.func.isRequired,
  subscribeFactoryDevices: PropTypes.func.isRequired,
  unsubscribeFactoryDevices: PropTypes.func.isRequired,
  isSignInPopupOpen: PropTypes.bool.isRequired,
  selectedMezzo: PropTypes.shape({
    serial: PropTypes.string,
    identifier: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  tokenExpireTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
}

MezzoConnection.defaultProps = {
  selectedMezzo: PropTypes.shape({
    serial: PropTypes.string,
    identifier: PropTypes.string,
  }),
  history: null,
}

export default withStyles(styles)(MezzoConnection)
