const convertDataToConsole = (data) => {
  if (!data || data.length === 0) {
    return ''
  }

  const convertedData = data.map(option => `option: ${option.number}, value: '${option.value}'`).join('\r\n')
  return convertedData
}


export default convertDataToConsole
