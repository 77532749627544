import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Wrapper from '../../../Common/Wrapper/Wrapper'
import styles from './MezzoMonitoring.style'
import MezzoMonitoringDetector from '../../../../containers/Provisioning/MezzoMonitoring/MezzoMonitoringDetector'
import MezzoMonitoringPendant from '../../../../containers/Provisioning/MezzoMonitoring/MezzoMonitoringPendant'

class MezzoMonitoring extends React.Component {
  render() {
    return (
      <Wrapper>
        <MezzoMonitoringPendant />
        <MezzoMonitoringDetector />
      </Wrapper>
    )
  }
}

export default withStyles(styles)(MezzoMonitoring)
