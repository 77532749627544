export const UPDATE_PROGRAM_OPTIONS = '@@programOptions/update'

export const SYNC_PROGRAM_OPTIONS = '@@programOptions/sync'
export const SYNC_SENT_PROGRAM_OPTIONS = '@@programOptions/syncSent'
export const SYNC_CANCEL_PROGRAM_OPTIONS = '@@programOptions/syncCancelled'
export const SYNC_FAILED_PROGRAM_OPTIONS = '@@programOptions/syncFailed'
export const SYNC_DONE_PROGRAM_OPTIONS = '@@programOptions/syncDone'

export const SET_PROGRAM_OPTIONS = '@@programOptions/set'
export const SET_CANCEL_PROGRAM_OPTIONS = '@@programOptions/setCancelled'

export const SELECT_PROGRAM_OPTIONS = '@@programOptions/selectOptions'
export const SELECT_PROGRAM_OPTION = '@@programOptions/selectOption'
export const SELECT_PROGRAM_OPTION_VALUE = '@@programOptions/selectOptionValue'

export const SET_PROGRAM = '@@programOptions/setProgram'
export const SET_CANCEL_PROGRAM = '@@programOptions/setCancelledProgram'

export const SET_CANCEL_TWR_OPTIONS = '@@programOptions/setCancelledTWR'
export const SET_DETECTOR_OPTIONS = '@@programOptions/setDetector'
export const SET_PENDANT_OPTIONS = '@@programOptions/setPendant'

export const UNSUBSCRIBE = '@@programOptions/unsubscribe'
export const SUBSCRIBE = '@@programOptions/subscribe'
export const CANCEL_SUBSCRIBE = '@@programOptions/cancel_subscribe'

export const RESET_PROGRAM_OPTIONS = '@@programOptions/reset'
