const styles = () => ({
  title: {
    color: '#000',
    marginTop: 10,
    marginBottom: 20,
  },
  buttonWrapper: {
    maxWidth: 440,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  button: {
    margin: 0,
  },
  '@media (max-width: 640px)': {
    buttonWrapper: {
      maxWidth: '100%',
    },
  },
})

export default styles
