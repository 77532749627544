import * as types from '../constants/servers'

export const selectServer = server => ({
  type: types.SELECT_SERVER,
  server,
})

export const saveEndpoints = endpoints => ({
  type: types.SAVE_ENDPOINTS,
  endpoints,
})

export const resetEndpointsData = () => ({
  type: types.RESET_ENDPOINTS_DATA,
})

export const setCurrentEndpoint = currentEndpoint => ({
  type: types.SET_CURRENT_ENDPOINT,
  currentEndpoint,
})
