const styles = () => ({
  tools: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: 20,
    color: '#000',
    marginBottom: 10,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    width: '48%',
  },
  button: {
    margin: 0,
    width: 70,
  },
  areaField: {
    width: '100%',
    margin: 0,
    verticalAlign: 'center',
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  mezzoButtons: {
    margin: '0 20px 0 0',
    textTransform: 'none',
    fontSize: '14px',
  },
  '@media (max-width: 640px)': {
    tools: {
      flexDirection: 'column',
    },
    buttonWrapper: {
      width: '100%',
    },
    buttonsWrapper: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    mezzoButtons: {
      width: '100%',
      margin: '0 0 10px 0',
    },
  },
})

export default styles
