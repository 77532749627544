import {
  UPDATE_PROGRAM_OPTIONS,

  SYNC_PROGRAM_OPTIONS,
  SYNC_SENT_PROGRAM_OPTIONS,
  SYNC_FAILED_PROGRAM_OPTIONS,
  SYNC_DONE_PROGRAM_OPTIONS,

  SET_PROGRAM_OPTIONS,

  SELECT_PROGRAM_OPTIONS,
  SELECT_PROGRAM_OPTION,
  SELECT_PROGRAM_OPTION_VALUE,

  SET_PROGRAM,

  SET_DETECTOR_OPTIONS,
  SET_PENDANT_OPTIONS,

  UNSUBSCRIBE,
  SUBSCRIBE,

  RESET_PROGRAM_OPTIONS,
} from '../constants/programOptions'

export const update = programOptions => ({
  type: UPDATE_PROGRAM_OPTIONS,
  programOptions,
})


export const sync = () => ({
  type: SYNC_PROGRAM_OPTIONS,
})

export const syncSent = () => ({
  type: SYNC_SENT_PROGRAM_OPTIONS,
})

export const syncDone = () => ({
  type: SYNC_DONE_PROGRAM_OPTIONS,
})
export const syncFailed = message => ({
  type: SYNC_FAILED_PROGRAM_OPTIONS,
  message,
})


export const set = () => ({
  type: SET_PROGRAM_OPTIONS,
})

export const selectOptions = options => ({
  type: SELECT_PROGRAM_OPTIONS,
  options,
})

export const selectOption = option => ({
  type: SELECT_PROGRAM_OPTION,
  option,
})

export const selectOptionValue = value => ({
  type: SELECT_PROGRAM_OPTION_VALUE,
  value,
})

export const setProgram = () => ({
  type: SET_PROGRAM,
})

export const setDetector = () => ({
  type: SET_DETECTOR_OPTIONS,
})

export const setPendant = () => ({
  type: SET_PENDANT_OPTIONS,
})

export const unsubscribeProgramOptions = () => ({
  type: UNSUBSCRIBE,
})

export const subscribeProgramOptions = () => ({
  type: SUBSCRIBE,
})

export const resetProgramOptions = () => ({
  type: RESET_PROGRAM_OPTIONS,
})
