import React from 'react'
import {
  Button,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import EditText from '../../../Common/EditText/EditText'
import SelectString from '../../../Common/SelectString/SelectString'
import Wrapper from '../../../Common/Wrapper/Wrapper'
import styles from './MezzoMonitoring.style'

class MezzoMonitoringForm extends React.Component {
  render() {
    const {
      classes,
      pristine,
      submitting,
      submit,
      title,
      inputValues,
      name,
    } = this.props
    return (
      <form>
        <Wrapper className={classes.mezzoMonitoringWrapper}>
          <Wrapper className={classes.title}>{title}</Wrapper>
          <Wrapper className={classes.mezzoMonitoring}>
            <EditText
              label="TXID"
              placeholder="TXID"
              title="TXID"
              name="TXID"
              className={classes.editText}
            />
            <Wrapper className={classes.selectWrapper}>
              <Typography>Name</Typography>
              <SelectString
                name={name}
                values={inputValues}
              />
            </Wrapper>
            <Button
              className={classes.mezzoMonitoringButton}
              disabled={pristine || submitting}
              variant="contained"
              color="primary"
              onClick={submit}
            >
              Add
            </Button>
          </Wrapper>
        </Wrapper>
      </form>
    )
  }
}

MezzoMonitoringForm.propTypes = {
  classes: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  inputValues: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
}


export default withStyles(styles)(MezzoMonitoringForm)
