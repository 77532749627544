import * as types from '../constants/secrets'

export const saveToken = (token, tokenExpireTime) => ({
  type: types.SAVE_TOKEN,
  token,
  tokenExpireTime,
})

export const resetSecrets = () => ({
  type: types.RESET_SECRETS,
})
